/* eslint-disable no-underscore-dangle */
import {
  createStore,
  combineReducers,
  applyMiddleware,
} from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import * as reducers from '../reducers';

const composeEnhancers = composeWithDevTools({
  // options like actionSanitizer, stateSanitizer
});
const appReducer = combineReducers({ ...reducers });
const preloadedState = typeof (window) === 'object' && window.__PRELOADED_STATE__ ? window.__PRELOADED_STATE__ : undefined;
const store = createStore(
  appReducer,
  preloadedState,
  composeEnhancers(applyMiddleware(thunk)),
);

export default store;
