import {
  SAVE_FORM_DATA,
  SAVE_FIELDS_BY_METHOD_FORM_DATA, UPDATE_RECEIPT_FIELD, SAVE_DELIVERY,
} from '../constants/actionTypes';

export function saveDelivery(data) {
  return {
    type: SAVE_DELIVERY,
    payload: data,
  };
}

export function saveFormData(method, data) {
  return {
    type: SAVE_FORM_DATA,
    payload: { method, data },
  };
}

export function saveFieldsByMethodFormData(method, data) {
  return {
    type: SAVE_FIELDS_BY_METHOD_FORM_DATA,
    payload: { method, data },
  };
}

export function updateReceiptField(fieldName, value) {
  return {
    type: UPDATE_RECEIPT_FIELD,
    payload: { fieldName, value },
  };
}
