import { formatMoneyWithCurrency } from './format';

export const getDeliveryPriceAmount = (delivery, payment, returnZeroIfNotPrepayment = true) => {
  if (!delivery || !delivery.chosenDelivery || (delivery.chosenDelivery.prepayment === false && returnZeroIfNotPrepayment)) return 0;
  const { chosenDelivery: { agent, price, customPriceSettings } } = delivery;
  if (price !== undefined && price !== null) return price;
  if (customPriceSettings && agent !== 'pickupPoint') {
    const { addressInfo: { cityWithType, regionWithType, country } } = delivery;
    const settings = customPriceSettings[cityWithType] ?? customPriceSettings[regionWithType] ?? customPriceSettings[country];
    const activeSetting = settings.length > 1
      ? settings.find(({ cartAmountFrom = Infinity, cartAmountTo = -Infinity }) => payment.amount >= cartAmountFrom || payment.amount <= cartAmountTo)
      : settings[0];

    return activeSetting.price.units;
  }
  return 0;
};

/*eslint-disable max-len*/
export const getFormattedDeliveryPrice = (delivery, payment, returnZeroIfNotPrepayment = true) => {
  const { chosenDelivery } = delivery;
  const formatted = formatMoneyWithCurrency(getDeliveryPriceAmount(delivery, payment, returnZeroIfNotPrepayment), { exponent: payment.exponent, currency: chosenDelivery.currency || payment.currency, digitsAfterComma: 0 });

  if (
    (chosenDelivery.price !== undefined && chosenDelivery.price !== null)
    || chosenDelivery.agent === 'ownDeliveryService'
    || chosenDelivery.agent === 'pickupPoint'
  ) return formatted;

  return '-';
};
/*eslint-enable max-len*/

export const isOwnDeliveryDisabledForCurrentAddress = ({ customPriceSettings } = {}, { cityWithType, regionWithType, country } = {}) => (
  !!customPriceSettings && !customPriceSettings[cityWithType] && !customPriceSettings[regionWithType] && !customPriceSettings[country]
);

export const isPickupPointDisabledForCurrentAddress = ({ pickupPoints } = {}, { cityWithType, regionWithType, country } = {}) => (
  !pickupPoints.some(({ area }) => area === cityWithType || area === regionWithType || area === country)
);
