import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

export default class Alert extends React.PureComponent {
  static propTypes = {
    color: PropTypes.string,
    closeTimeoutMS: PropTypes.number,
    isOpen: PropTypes.bool,
    closeOnClick: PropTypes.bool,
    onClose: PropTypes.func,
    children: PropTypes.node,
  };

  static defaultProps = {
    color: 'white',
    closeTimeoutMS: 3500,
    isOpen: false,
    children: null,
    closeOnClick: false,
    onClose: () => {},
  };

  static Color = {
    blue: 'blue',
    green: 'green',
    yellow: 'yellow',
    red: 'red',
    white: 'white',
  };

  constructor(props) {
    super(props);

    this.timeoutId = 0;
  }

  componentDidUpdate() {
    if (this.props.isOpen) {
      if (this.props.closeTimeoutMS) {
        this.timeoutId = setTimeout(this.props.onClose, this.props.closeTimeoutMS);
      }
    }
  }

  componentWillUnmount() {
    if (this.props.isOpen) {
      this.props.onClose();
    }
    clearTimeout(this.timeoutId);
  }

  onClick = () => {
    if (this.props.closeOnClick) {
      this.props.onClose();
    }
  };

  render() {
    const { children, color, isOpen, closeOnClick } = this.props;
    const className = classnames(`ui-alert ui-alert-${color}`, { 'ui-alert-closable-on-click': closeOnClick });

    return (
      <CSSTransition
        classNames="ui-alert"
        timeout={300}
        in={isOpen}
        mountOnEnter
        unmountOnExit
      >
        <div className={className} onClick={this.onClick}>
          {children}
        </div>
      </CSSTransition>
    );
  }
}
