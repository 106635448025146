import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Manager, Reference, Popper } from 'react-popper';
import { CSSTransition } from 'react-transition-group';
import newid from '../../utils/newid';

// preventOverflow: { enabled: false }
const popperModifiers = [{ name: 'hide', enabled: false }, { name: 'preventOverflow', enabled: true }, { name: 'flip', enabled: false }];

class Tooltip extends PureComponent {
  static propTypes = {
    children: PropTypes.func.isRequired,
    opened: PropTypes.bool.isRequired,
    tooltipText: PropTypes.string,
    autoCloseTime: PropTypes.number,
    onClose: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    tooltipText: '',
    autoCloseTime: 2000,
  };

  constructor(props) {
    super(props);

    this.container = window.document.querySelector('.disabled-tooltips-container');

    if (!this.container) {
      this.container = window.document.createElement('div');
      this.container.id = newid();
      this.container.classList.add('disabled-tooltips-container');
      this.containerIsMounted = false;
    } else {
      this.containerIsMounted = true;
    }
  }

  componentDidMount() {
    if (!this.containerIsMounted) {
      window.document.body.appendChild(this.container);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { opened, onClose, autoCloseTime } = this.props;

    if (prevProps.opened !== opened) {
      clearTimeout(this.autoCloseTimerId);

      if (opened) {
        window.document.addEventListener('click', this.onDocumentClick);
        this.autoCloseTimerId = setTimeout(() => {
          onClose();
        }, autoCloseTime);
      } else {
        window.document.removeEventListener('click', this.onDocumentClick);
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.autoCloseTimerId);
    window.document.removeEventListener('click', this.onDocumentClick);

    this.containerIsMounted = false;
  }

  onDocumentClick = () => {
    const { opened, onClose } = this.props;

    if (!opened) {
      return;
    }

    onClose();
  };

  renderPopper = ({ ref, style, placement, arrowProps }) => {
    const { opened, tooltipText, t } = this.props;

    return (
      <CSSTransition
        classNames="disabled-tooltip-wrap"
        timeout={150}
        in={opened}
        mountOnEnter
        unmountOnExit
      >
        <div ref={ref} style={style} data-placement={placement} className="disabled-tooltip-wrap">
          <div className="disabled-tooltip">
            <div className="tooltip-content" dangerouslySetInnerHTML={{ __html: tooltipText || t('methods.limit_exceeded') }} />
            <div className="tooltip-tail" ref={arrowProps.ref} style={arrowProps.style} />
          </div>
        </div>
      </CSSTransition>
    );
  };

  render() {
    const { children } = this.props;

    return (
      <Manager>
        <Reference>
          {children}
        </Reference>
        {ReactDOM.createPortal(
          <Popper placement="top" eventsEnabled modifiers={popperModifiers}>
            {this.renderPopper}
          </Popper>,
          this.container,
        )}
      </Manager>
    );
  }
}

export default withTranslation()(Tooltip);
