import client from '../utils/api-client';
import {
  CHANGE_CURRENT_STEP,
  CALC_FEE,
  ADD_DELIVERY,
  UPDATE_STORE_DATA_FIELD,
  INIT_PAYMENT,
  GET_PAYMENT_STATUS,
  GET_PAYMENT_DATA,
  GET_EMAIL,
  SAVE_EMAIL,
  SEND_EMAIL,
  CREATE_ISSUE,
  RESET_TX_DATA,
  SEND_CONFIRMATION_CODE,
  CHECK_CONFIRMATION_CODE,
} from '../constants/actionTypes';
import { makeCancelable } from '../utils/cancelablePromise';

export function calcFee({ product, payment, payer, extra }) {
  return (dispatch, getState) => {
    const state = getState();
    const { sessionId } = state.app;
    const cancelablePromise = makeCancelable(client({ path: '/calc-fee', method: 'POST', withSessionHeader: true, sessionId, noNotifyOnCodes: [1002] }, { product, payment, payer, extra }));

    cancelablePromise.promise = cancelablePromise
      .promise
      .then(payload => dispatch({ type: CALC_FEE, payload }))
      .catch((error) => {
        console.log(error);
        if (error && error.isCanceled) return Promise.reject(error);
        return dispatch({ type: CALC_FEE, payload: { payment: { commission: 0 } } });
      });

    return cancelablePromise;
  };
}

export function addDelivery(data) {
  return (dispatch, getState) => {
    const state = getState();
    const { sessionId } = state.app;

    return client({ path: '/add-delivery', method: 'POST', withSessionHeader: true, sessionId }, data)
      .then(payload => dispatch({ type: ADD_DELIVERY, payload, meta: data }));
  };
}

let lastPaymentAmountAndReceiptEmail;

export function initPayment({ payment, payer, extra, receiptEmail, noFieldsMethod }) {
  return (dispatch, getState) => {
    const state = getState();
    const { sessionId } = state.app;
    lastPaymentAmountAndReceiptEmail = `${payment.amount}-${receiptEmail}`;

    return client({ path: '/init-payment', method: 'POST', withSessionHeader: true, sessionId }, { payment, payer, extra, ...(receiptEmail ? { receiptEmail } : null) })
      .then((payload) => {
        if (lastPaymentAmountAndReceiptEmail !== `${payment.amount}-${receiptEmail}`) return;
        // eslint-disable-next-line consistent-return
        return dispatch({ type: INIT_PAYMENT, payload, meta: { extra, noFieldsMethod, isSbp: payer.payment_system === 'sbp' } });
      });
  };
}

export function sendConfirmationCode({ payment, payer, extra, salt }) {
  const data = { payment, payer, extra };

  if (salt) {
    data.salt = salt;
  }

  return (dispatch, getState) => {
    const state = getState();
    const { sessionId } = state.app;

    return client({ path: '/send-confirmation-code', method: 'POST', withSessionHeader: true, sessionId }, data)
      .then(payload => dispatch({ type: SEND_CONFIRMATION_CODE, payload, meta: { payment, payer, extra } }));
  };
}

export function checkConfirmationCode({ payment, payer, extra, salt, code }) {
  return (dispatch, getState) => {
    const state = getState();
    const { sessionId } = state.app;

    return client({ path: '/check-confirmation-code', method: 'POST', withSessionHeader: true, sessionId }, { payment, payer, extra, salt, code })
      .then(payload => dispatch({ type: CHECK_CONFIRMATION_CODE, payload }));
  };
}

export function getPaymentStatus({ tx_id, isSbp }) {
  return dispatch => client({ path: '/get-status', method: 'POST' }, { tx_id })
    .then(payload => dispatch({ type: GET_PAYMENT_STATUS, payload, meta: { isSbp } }));
}

export function getPaymentData({ tx_id }) {
  return (dispatch, getState) => {
    const state = getState();

    if (state.data.txStarted) {
      return Promise.resolve();
    }

    return client({ path: '/get-payment', method: 'POST' }, { tx_id })
      .then((payload) => {
        dispatch({ type: GET_PAYMENT_DATA, payload });
        dispatch({ type: UPDATE_STORE_DATA_FIELD, payload: { field: 'amountAndProductShown', value: true } });

        return payload;
      });
  };
}

export function getEmail({ tx_id }) {
  return (dispatch, getState) => {
    const state = getState();

    if (state.data.email) {
      return Promise.resolve();
    }

    return client({ path: '/get-email', method: 'POST', noNotifyOnCodes: [1007] }, { tx_id })
      .then(payload => dispatch({ type: GET_EMAIL, payload }));
  };
}

export function saveEmail({ tx_id, email }) {
  return dispatch => client({ path: '/save-email', method: 'POST' }, { tx_id, email })
    .then(() => dispatch({ type: SAVE_EMAIL, payload: { email } }));
}

export function sendEmail({ tx_id, email }) {
  return dispatch => client({ path: '/send-email', method: 'POST' }, { tx_id, email })
    .then(() => dispatch({ type: SEND_EMAIL, payload: { email } }));
}

export function createIssue({ email, topic, message }) {
  return dispatch => client({ path: '/create-issue', method: 'POST' }, { email, topic, message })
    .then(() => dispatch({ type: CREATE_ISSUE, payload: { email, topic, message } }));
}


export function changeCurrentStep(currentStepIndex) {
  return dispatch => dispatch({ type: CHANGE_CURRENT_STEP, payload: { currentStepIndex } });
}

export function updateStoreDataField({ field, value }) {
  return dispatch => dispatch({ type: UPDATE_STORE_DATA_FIELD, payload: { field, value } });
}

export function resetTxData() {
  return dispatch => dispatch({ type: RESET_TX_DATA });
}
