import { GET_FORM, INIT_DATA, UPDATE_STORE_APP_FIELD, SET_LINK_EXPIRED } from '../constants/actionTypes';

const initialSteps = [
  {
    key: 'delivery',
    title: 'Оформление доставки',
    options: [
      {
        agent: 'cdek',
        vat: 10,
        defaultWidth: 100,
        defaultLength: 100,
        defaultHeight: 100,
        defaultWeight: 1000,
      },
    ],
  },
  {
    key: 'products',
    title: 'Вы получили ссылку на оплату. Ознакомьтесь с перечнем выбранных товаров',
  },
  {
    key: 'info',
    currentSubStepIndex: 0,
    subSteps: [
      {
        key: 'address',
        title: 'Адрес выставления счета',
        fields: [
          { label: 'Повторить адрес доставки', type: 'checkbox', name: 'repeatDeliveryAddress', width: 100 },
          {
            label: 'Валюта', type: 'radio', name: 'currency', width: 100, items: [{ value: 'test1', name: 'RUB', width: 50 }, { value: 'test2', name: 'USD' }], validatorConfig: { required: true, regexp: '', regexpErrorMsg: '' },
          },
          {
            label: 'Оператор', type: 'select', name: 'operator', width: 100, items: [{ value: 'beeline', name: 'Beeline' }, { value: 'tele2', name: 'Tele2' }, { value: 'mts', name: 'MTS' }], validatorConfig: { required: true, regexp: '', regexpErrorMsg: '' },
          },
          {
            label: 'Область, регион', type: 'text', name: 'region', validatorConfig: { required: false, regexp: '', regexpErrorMsg: '' }, width: 100, icon: 'assets/svg/flag.svg',
          },
          {
            label: 'Город', type: 'text', name: 'city', validatorConfig: { required: false, regexp: '', regexpErrorMsg: '' }, width: 60, icon: 'assets/svg/region.svg',
          },
          {
            label: 'Индекс', type: 'number', name: 'index', validatorConfig: { required: false, regexp: '', regexpErrorMsg: '' }, width: 40, icon: 'assets/svg/index.svg',
          },
          {
            label: 'Адрес', type: 'text', name: 'address', validatorConfig: { required: false, regexp: '', regexpErrorMsg: '' }, width: 100, icon: 'assets/svg/address-book.svg',
          },
          {
            label: 'Комментарий', type: 'textarea', name: 'comment', validatorConfig: { required: false, regexp: '', regexpErrorMsg: '' }, width: 100, icon: 'assets/svg/message.svg',
          },
        ],
      },
      {
        key: 'personal',
        title: 'Ваши данные',
        subtitle: 'Для участия в лотерее нужно оставить свои данные',
        fields: [
          {
            label: 'Ваше имя', type: 'text', name: 'firstName', validatorConfig: { required: false, regexp: '', regexpErrorMsg: '' }, width: 50, icon: 'assets/svg/user.svg',
          },
          {
            label: 'Ваша фамилия', type: 'text', name: 'lastName', validatorConfig: { required: false, regexp: '', regexpErrorMsg: '' }, width: 50, icon: 'assets/svg/user.svg',
          },
          {
            label: 'E-mail', type: 'text', name: 'email', validatorConfig: { required: false, regexp: '^.+@.+\\.[a-zA-Z]{2,4}$', regexpErrorMsg: 'Некорректный email' }, width: 100, icon: 'assets/svg/mail.svg',
          },
        ],
      },
    ],
  },
  {
    key: 'recurrent',
    title: 'Помочь детям',
    tabs: [
      {
        key: 'single',
        title: 'Однократно',
        amountOptions: [10000, 30000, 50000, 100000, 300000, 500000],
        defaultAmountOption: 10000,
        withYourAmountInput: true,
      },
      // {
      //   key: 'regular',
      //   title: 'Регулярный платеж',
      //   periodOptions: [86400000, 604800000, 1209600000, 2592000000],
      //   defaultPeriodOption: 604800000,
      //   amountOptions: [30000, 50000, 100000, 500000],
      //   defaultAmountOption: 0,
      //   withYourAmountInput: true,
      // },
    ],
  },
  {
    key: 'methods',
    title: 'Методы оплаты',
    tabs: [
      {
        key: 'bank-card',
        title: 'Банковская карта',
        shortTitle: 'Карта',
        groups: [
          {
            key: 'bank-card-group',
            methods: [
              {
                key: 'bank-card-method',
                payment_type: 'api',
                redirectFormTarget: '_self',
                maxAmount: 14000000,
                fields: [
                  {
                    name: 'card_number',
                    label: 'Номер карты',
                    type: 'number',
                    pattern: '[0-9]*',
                    inputmode: 'numeric',
                    mask: '9999 9999 9999 9999',
                    placeholder: '0000 0000 0000 0000',
                    validatorConfig: { required: true, regexp: '', regexpErrorMsg: '' },
                    width: 100,
                  },
                  {
                    name: 'expire_date',
                    label: 'Месяц/год',
                    type: 'number',
                    pattern: '[0-9]*',
                    inputmode: 'numeric',
                    mask: '99 / 99',
                    placeholder: 'ММ / ГГ',
                    validatorConfig: { required: true, regexp: '^(0[1-9]|1[0-2]) / ?([0-9]{4}|[0-9]{2})$', regexpErrorMsg: 'Некорректная дата' },
                    width: 50,
                    icon: 'assets/svg/cal.svg',
                  },
                  {
                    name: 'cvc',
                    label: 'Код CVC',
                    type: 'password',
                    pattern: '[0-9]*',
                    inputmode: 'numeric',
                    placeholder: '***',
                    maxLength: 3,
                    hint: 'CVV/CVC код находится на задней стороне карты',
                    validatorConfig: { required: true, regexp: '', regexpErrorMsg: '' },
                    width: 50,
                    icon: 'assets/svg/cvc.svg',
                  },
                  {
                    name: 'holder_name',
                    label: 'Имя держателя карты',
                    type: 'text',
                    placeholder: 'IVAN PETROV',
                    validatorConfig: { required: false, regexp: '', regexpErrorMsg: '' },
                    width: 100,
                    icon: 'assets/svg/user.svg',
                  },
                ],
                submitBtnText: 'Оплатить {{amount}}',
              },
            ],
          },
        ],
      },
      {
        key: 'mobile-pay',
        title: 'Мобильный платеж',
        groups: [
          {
            key: 'mobile-pay-group',
            methods: [
              {
                key: 'mobile-pay-method',
                paymentType: 'api-redirect-card',
                maxAmount: 1200000,
                maxAmountByOperator: {
                  beeline: 1500000,
                  tele2: 500000,
                  mts: 1499900,
                  megafon: 1500000,
                  yota: 1500000,
                },
                // comment: 'Обратите внимание,  что для осуществления платежа данным методом, баланс лицевого счета должен быть положительным и остаток на счете после совершения платежа должен составлять не менее 50 рублей.',
                fields: [
                  {
                    name: 'phone_number',
                    label: 'Номер телефона',
                    type: 'tel',
                    mask: '999 999 9999',
                    placeholder: '000 000 0000',
                    validatorConfig: { required: true, regexp: '', regexpErrorMsg: '' },
                    width: 100,
                    icon: 'assets/svg/phone.svg',
                  },
                ],
                submitBtnText: 'Оплатить {{amount}}',
              },
            ],
          },
        ],
      },
      {
        key: 'other',
        title: 'Другое',
        groups: [
          // {
          //   key: 'terminal',
          //   title: 'Через терминал',
          //   methods: [
          //     {
          //       key: 'rostelecom',
          //       title: 'Ростелеком',
          //       helpText: 'Мы отправим номер платежа на Ваш e-mail, который нужно будет оплатить в любом терминале сети Ростелеком',
          //       submitBtnText: 'Отправить',
          //       fields: [
          //         {
          //           name: 'email',
          //           label: 'E-mail',
          //           type: 'text',
          //           validatorConfig: { required: true, regexp: '^.+@.+\\.[a-zA-Z]{2,4}$', regexpErrorMsg: 'Некорректный email' },
          //           width: 100,
          //           icon: 'assets/svg/mail.svg',
          //         },
          //       ],
          //     },
          //     {
          //       key: 'trademobile1',
          //       title: 'Трейд Мобайл',
          //     },
          //     {
          //       key: 'evroset1',
          //       title: 'ЕВРОСЕТЬ',
          //     },
          //     {
          //       key: 'qiwi1',
          //       title: 'Qiwi',
          //     },
          //     {
          //       key: 'eleksnet1',
          //       title: 'Элекснет',
          //     },
          //     {
          //       key: 'svyaznoi',
          //       title: 'СВЯЗНОЙ',
          //     },
          //     {
          //       key: 'trademobile2',
          //       title: 'Трейд Мобайл',
          //     },
          //     {
          //       key: 'evroset2',
          //       title: 'ЕВРОСЕТЬ',
          //     },
          //     {
          //       key: 'qiwi2',
          //       title: 'Qiwi',
          //     },
          //     {
          //       key: 'eleksnet2',
          //       title: 'Элекснет',
          //     },
          //   ],
          // },
          {
            key: 'ewallet',
            title: 'Электронные кошельки',
            methods: [
              {
                key: 'qiwi_wallet',
                title: 'Qiwi',
                iconName: 'qiwi_wallet',
                helpText: 'Для оплаты с Qiwi кошелька введите свой номер телефона и e-mail',
                submitBtnText: 'Перейти к оплате',
                idFieldName: 'phone_number',
                maxAmount: 15000000,
                fields: [
                  {
                    name: 'phone_number',
                    label: 'Номер телефона',
                    type: 'tel',
                    mask: '+7\\ (999) 999 9999',
                    placeholder: '+7 (000) 000 0000',
                    validatorConfig: { required: true, regexp: '', regexpErrorMsg: '' },
                    width: 100,
                    icon: 'assets/svg/phone.svg',
                  },
                  {
                    name: 'email',
                    label: 'E-mail',
                    type: 'text',
                    validatorConfig: { required: true, regexp: '^.+@.+\\.[a-zA-Z]{2,4}$', regexpErrorMsg: 'Некорректный email' },
                    width: 100,
                    icon: 'assets/svg/mail.svg',
                  },
                ],
              },
              {
                key: 'walletone',
                title: 'WalletOne',
                iconName: 'walletone',
                maxAmount: 10000000,
              },
              {
                key: 'yandex_wallet',
                title: 'Яндекс Деньги',
                iconName: 'yandex_wallet',
                helpText: 'Для оплаты с электронного кошелька Яндекс.Деньги, оставьте свой телефон',
                submitBtnText: 'Перейти к оплате',
                idFieldName: 'phone_number',
                fields: [
                  {
                    name: 'phone_number',
                    label: 'Номер телефона',
                    type: 'tel',
                    mask: '+7\\ (999) 999 9999',
                    placeholder: '+7 (000) 000 0000',
                    validatorConfig: { required: true, regexp: '', regexpErrorMsg: '' },
                    width: 100,
                    icon: 'assets/svg/phone.svg',
                  },
                ],
              },
            ],
          },
          {
            key: 'internet-bank',
            title: 'Интернет банк',
            methods: [
              // {
              //   key: 'alfa_money',
              //   title: 'Альфа-Клик',
              //   iconName: 'alfa_money',
              //   submitBtnText: 'Перейти к оплате',
              //   idFieldName: 'id_or_number',
              //   fields: [
              //     {
              //       name: 'id_or_number',
              //       label: 'ID или номер телефона',
              //       type: 'number',
              //       validatorConfig: { required: true, regexp: '', regexpErrorMsg: '' },
              //       width: 100,
              //       icon: 'assets/svg/user.svg',
              //     },
              //   ],
              // },
              // {
              //   key: 'sber_online',
              //   title: 'Сбербанк Онлайн',
              //   iconName: 'sber_online',
              // },
              {
                key: 'psbank',
                title: 'Промсвязьбанк',
                iconName: 'psbank',
                maxAmount: 10000000,
              },
            ],
          },
          // {
          //   key: 'crypto',
          //   title: 'Криптовалюта',
          //   methods: [
          //     {
          //       key: 'ethereum',
          //       title: 'Ethereum',
          //     },
          //     {
          //       key: 'bitcoin',
          //       title: 'Bitcoin',
          //       helpText: 'Для оплаты с помощью криптовалюты Bitcoin, введите свой e-mail',
          //       fields: [
          //         {
          //           name: 'email',
          //           label: 'E-mail',
          //           type: 'text',
          //           validatorConfig: { required: true, regexp: '^.+@.+\\.[a-zA-Z]{2,4}$', regexpErrorMsg: 'Некорректный email' },
          //           width: 100,
          //           icon: 'assets/svg/mail.svg',
          //         },
          //       ],
          //     },
          //   ],
          // },
        ],
      },
    ],
  },
];

const initialState = {
  locale: 'ru',
  linkIsActive: true,
  initiator: {
    id: 'lirota9508__hagendes_com_buran',
  },
  product: {
    id: 'fines_card',
    name: 'Оплата штрафа ГИБДД',
    url: 'https://www.gibdd.ru',
    description: 'Лучшая оплата штрафов',
    redirectUrl: 'https://gibdd.ru',
  },
  productItems: [
    {
      vat: 200, name: 'Товар', quantity: 1, price: 1200, vatPercent: 20, isVatInclusive: true, vatCalculationAttribute: 'commodity', discount: 0,
    },
  ],
  payment: {
    amount: 0,
    currency: 643,
    exponent: 2,
    isRegular: false,
    mutable: true,
    period: 0,
    maxAmount: 15000000,
  },
  theme: {
    primaryBgColor: '#3d5afe',
    primaryTextColor: '#ffffff',
  },
  steps: initialSteps,
  payMethods: getPayMethods(initialSteps),
};

function convertBoxTypes(type) {
  switch (type) {
    case 'enum':
      return 'select';
    case 'phone':
      return 'phone_number';
    default:
      return type;
  }
}

function formatBoxStepsToPaycafe(steps, extraFields) {
  if (!extraFields) {
    return steps;
  }

  return steps.map((step) => {
    const formattedBoxSteps = extraFields.filter(field => field.required).map(field => ({
      label: field.caption,
      type: convertBoxTypes(field.fieldType),
      name: field.fieldName,
      width: 100,
      validatorConfig: {
        required: true,
        regexp: field.regEx,
      },
      ...field,
    }));

    if (step.key === 'info') {
      return {
        ...step,
        subSteps: [
          {
            key: 'extraFields',
            title: 'Дополнительные поля',
            fields: formattedBoxSteps,
          },
          ...step.subSteps,
        ],
      };
    }
    return step;
  });
}

function setField(keys, obj, value) {
  if (keys.length > 1) {
    setField(keys.slice(1), obj[keys[0]], value);
  } else {
    obj[keys[0]] = value;
  }
}

export default function app(state = initialState, action) {
  switch (action.type) {
    case GET_FORM:
      return {
        ...state,
        ...action.payload,
      };

    case INIT_DATA:
      const { steps, payment: { mutable }, extraFields } = action.payload;
      const filteredSteps = mutable ? steps : steps.filter(step => step.key !== 'recurrent');
      // const paymentStep = filteredSteps.find(step => step.key === 'methods');
      // const bankCardTabIndex = paymentStep.tabs.findIndex(tab => tab.key === 'bank-card');

      // now backed will decide - add to tabs or add isSbpReady to method
      // if (paymentStep.tabs.find(tab => tab.key === 'sbp') && bankCardTabIndex > -1) {
      //   paymentStep.tabs[bankCardTabIndex].groups[0].methods[0].isSbpReady = true;
      //   paymentStep.tabs = paymentStep.tabs.filter(tab => tab.key !== 'sbp');
      // }

      return {
        ...state,
        // steps: filteredSteps,
        steps: formatBoxStepsToPaycafe(filteredSteps, extraFields),
        payMethods: getPayMethods(filteredSteps),
        ...action.meta,
      };

    case UPDATE_STORE_APP_FIELD:
      const { field, value } = action.payload;

      if (typeof field !== 'string') return state;

      const keysArr = field.split('.');
      const fKey = keysArr[0];
      const fKeyVal = state[fKey];
      const newState = { [fKey]: typeof fKeyVal === 'object' ? { ...fKeyVal } : fKeyVal };

      setField(keysArr, newState, value);

      return {
        ...state,
        ...newState,
      };

    case SET_LINK_EXPIRED:
      return { ...state, linkExpired: true };
    default:
      return state;
  }
}

function getPayMethods(steps) {
  const methodStep = steps.find(step => step.key === 'methods');
  let methods = {};

  for (const tab of methodStep.tabs) {
    for (const group of tab.groups) {
      for (const method of group.methods) {
        methods = {
          ...methods,
          [method.key]: {
            ...method,
            groupKey: group.key,
            tabKey: tab.key,
          },
        };
      }
    }
  }

  return methods;
}
