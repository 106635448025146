import { connect } from 'react-redux';
import { getForm, setLinkExpired } from '../actions/app';
import {
  calcFee,
  addDelivery,
  getPaymentStatus,
  initPayment,
  updateStoreDataField,
  changeCurrentStep,
  getPaymentData,
  getEmail,
  saveEmail,
  sendEmail,
  createIssue,
  resetTxData,
  sendConfirmationCode,
  checkConfirmationCode,
} from '../actions/data';
import { saveDelivery, saveFormData, saveFieldsByMethodFormData } from '../actions/formData';
import App from './App';

const mapStateToProps = state => ({
  app: state.app,
  data: state.data,
  formData: state.formData,
});

const mapDispatchToProps = {
  getForm,
  calcFee,
  initPayment,
  sendConfirmationCode,
  checkConfirmationCode,
  getPaymentStatus,
  getPaymentData,
  getEmail,
  saveEmail,
  sendEmail,
  createIssue,
  updateStoreDataField,
  changeCurrentStep,
  resetTxData,
  addDelivery,
  saveDelivery,
  saveFormData,
  saveFieldsByMethodFormData,
  setLinkExpired,
};

const AppContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);

export default AppContainer;
