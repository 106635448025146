// App -----------------------------------
export const LOGOUT = 'LOGOUT';
export const GET_FORM = 'GET_FORM';
export const INIT_DATA = 'INIT_DATA';
export const CALC_FEE = 'CALC_FEE';
export const ADD_DELIVERY = 'ADD_DELIVERY';
export const INIT_PAYMENT = 'INIT_PAYMENT';
export const SEND_CONFIRMATION_CODE = 'SEND_CONFIRMATION_CODE';
export const CHECK_CONFIRMATION_CODE = 'CHECK_CONFIRMATION_CODE';
export const GET_PAYMENT_STATUS = 'GET_PAYMENT_STATUS';
export const GET_PAYMENT_DATA = 'GET_PAYMENT_DATA';
export const GET_EMAIL = 'GET_EMAIL';
export const SAVE_EMAIL = 'SAVE_EMAIL';
export const SEND_EMAIL = 'SEND_EMAIL';
export const CREATE_ISSUE = 'CREATE_ISSUE';
export const CHANGE_CURRENT_STEP = 'CHANGE_CURRENT_STEP';
export const UPDATE_STORE_APP_FIELD = 'UPDATE_STORE_APP_FIELD';
export const UPDATE_STORE_DATA_FIELD = 'UPDATE_STORE_DATA_FIELD';
export const RESET_TX_DATA = 'RESET_TX_DATA';
export const SAVE_DELIVERY = 'SAVE_DELIVERY';
export const SAVE_FORM_DATA = 'SAVE_FORM_DATA';
export const SAVE_FIELDS_BY_METHOD_FORM_DATA = 'SAVE_FIELDS_BY_METHOD_FORM_DATA';
export const SET_LINK_EXPIRED = 'SET_LINK_EXPIRED';
export const UPDATE_RECEIPT_FIELD = 'UPDATE_RECEIPT_FIELD';
