/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

export const StepHeaderContext = React.createContext(null);

class StepHeaderManager extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  state = {
    props: null,
    update: this.update.bind(this),
  };

  update(props) {
    this.setState({ props });
  }

  render() {
    const { children } = this.props;

    return (
      <StepHeaderContext.Provider value={this.state}>
        {children}
      </StepHeaderContext.Provider>
    );
  }
}

export default StepHeaderManager;
