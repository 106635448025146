import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import './AliveFormLabel.scss';


class AliveFormLabel extends React.PureComponent {
  static propTypes = {
    endTime: PropTypes.number.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.timerId = null;
  }

  componentDidMount() {
    const days = Math.floor((this.props.endTime - Date.now()) / 1000 / 60 / 60 / 24);
    if (days === 0) {
      this.timerId = setInterval(() => {
        const now = Date.now();

        if (now >= this.props.endTime) {
          clearInterval(this.timerId);
        }
        this.forceUpdate();
      }, 1000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  getTime() {
    const now = Date.now();
    const { endTime } = this.props;
    const duration = moment.duration(endTime - now);

    if (duration.get('days') > 0) {
      return moment(endTime).format('DD.MM.YYYY');
    }

    let hours = Math.max(duration.get('hours'), 0);
    let minutes = Math.max(duration.get('minutes'), 0);
    let seconds = Math.max(duration.get('seconds'), 0);

    minutes = minutes < 10 ? `0${minutes}` : minutes;
    seconds = seconds < 10 ? `0${seconds}` : seconds;

    if (hours > 0) {
      hours = hours < 10 ? `0${hours}` : hours;

      return `${hours}:${minutes}:${seconds}`;
    }

    return `${minutes}:${seconds}`;
  }

  render() {
    const { t, endTime } = this.props;
    const days = Math.floor((endTime - Date.now()) / 1000 / 60 / 60 / 24);

    return (
      <div className="alive-time">
        <span className="label" dangerouslySetInnerHTML={{ __html: days > 1 ? t('products.payment_available_by') : t('products.time_to_pay') }} />
        <span>{this.getTime()}</span>
      </div>
    );
  }
}

export default withTranslation()(AliveFormLabel);
