import moment from 'moment';

export const getLink = (url) => {
  const hasProtocol = url.indexOf('//') > -1;
  return hasProtocol ? url : `//${url}`;
};

/**
 * Ссылка назад в магазин на экранах статуса платежа pending/error/success
 * @param {string} url
 * @param {'ok'|'failed'|'pending'} status
 * @returns {string}
 */
export const getLinkWithPaymentStatusParam = (url, status) => {
  const alreadyHasQueryParams = url.indexOf('?') > -1;
  return getLink(`${url}${alreadyHasQueryParams ? '&' : '?'}paymentStatus=${status}`);
};

export function toSnakeCase(str) {
  return str.replace(/([A-Z])/g, $1 => `_${$1.toLowerCase()}`);
}

export function formatAddress(addresspointModel) {
  if (!addresspointModel) {
    return '';
  }

  const address = [];

  if (addresspointModel.houseNumber) {
    address.push(addresspointModel.houseNumber);
  }
  if (addresspointModel.road) {
    address.push(addresspointModel.road);
  }
  if (addresspointModel.city) {
    address.push(addresspointModel.city);
  }

  return address.join(', ');
}

export function formatStreet(addresspointModel) {
  if (!addresspointModel) {
    return '';
  }

  const address = [];

  if (addresspointModel.houseNumber) {
    address.push(addresspointModel.houseNumber);
  }
  if (addresspointModel.road) {
    address.push(addresspointModel.road);
  }

  return address.join(', ');
}

export function formatCity(addresspointModel) {
  if (!addresspointModel) {
    return '';
  }

  const address = [];

  if (addresspointModel.city) {
    address.push(addresspointModel.city);
  }
  if (addresspointModel.state) {
    address.push(addresspointModel.state);
  }

  return address.join(', ');
}

export function formatAddressForTable(addresspointModel) {
  if (!addresspointModel) {
    return '';
  }

  const address = [];

  if (addresspointModel.road) {
    address.push(addresspointModel.road);
  }
  if (addresspointModel.city) {
    address.push(addresspointModel.city);
  }

  return address.join('|');
}

export function formatSerialNumber(serial) {
  return serial && serial.replace(/(\w)(?=(\w{5})+([^\w]|$))/g, '$1 - ');
}

export function formatDate(date) {
  return moment(date).format('MM-DD-YYYY H:mm a');
}

/**
 * Duration to string
 * @param {number} duration - duration in seconds
 * @param {object} [options] - format options
 * @param {number} [options.count = 3] - max units count
 * @param {boolean} [options.short = true] - use short unit label
 * @returns {string}
 */
export function formatDuration(duration, options = {}) {
  if (!duration) {
    return '0s';
  }

  const md = moment.duration(duration, 'seconds');
  const result = [];
  const {
    count = 3,
    short = true,
  } = options;

  for (const unit of durationUnits(md)) {
    if (unit.value || result.length) {
      result.push(short ? getShortDurationLabel(unit) : getLongDurationLabel(unit));
    }

    if (result.length === count) {
      break;
    }
  }

  return result.join(' ');
}

function plural(s, pl) {
  const n = parseFloat(s);
  if (Number.isNaN(n) || Math.abs(n) === 1) {
    return s;
  }
  if (!pl) {
    return `${s}s`;
  }
  return s.replace(/\S+(\s*)$/, `${pl}$1`);
}

const units = {
  years: 'years',
  months: 'months',
  days: 'days',
  hours: 'hours',
  minutes: 'minutes',
  seconds: 'seconds',
};

/**
 * @param {moment.Duration} duration
 * @return {Iterable.<*>}
 */
function* durationUnits(duration) {
  yield { value: duration.years(), key: units.years };
  yield { value: duration.months(), key: units.months };
  yield { value: duration.days(), key: units.days };
  yield { value: duration.hours(), key: units.hours };
  yield { value: duration.minutes(), key: units.minutes };
  yield { value: duration.seconds(), key: units.seconds };
}

function getShortDurationLabel({ value, key }) {
  switch (key) {
    case units.years:
      return `${value}y`;
    case units.months:
      return `${value}m`;
    case units.days:
      return `${value}d`;
    case units.hours:
      return `${value}h`;
    case units.minutes:
      return `${value}m`;
    case units.seconds:
      return `${value}s`;
    default:
      throw new Error(`Incorrect duration key: ${key}`);
  }
}

function getLongDurationLabel({ value, key }) {
  switch (key) {
    case units.years:
      return plural(`${value} year`);
    case units.months:
      return plural(`${value} month`);
    case units.days:
      return plural(`${value} day`);
    case units.hours:
      return plural(`${value} hour`);
    case units.minutes:
      return plural(`${value} min`, 'min');
    case units.seconds:
      return plural(`${value} sec`, 'sec');
    default:
      throw new Error(`Incorrect duration key: ${key}`);
  }
}

export function getNoun(number, one, two, five, returnWithNumber) {
  let n = Math.abs(number);
  n %= 100;
  if (n >= 5 && n <= 20) {
    return returnWithNumber ? `${number} ${five}` : five;
  }
  n %= 10;
  if (n === 1) {
    return returnWithNumber ? `${number} ${one}` : one;
  }
  if (n >= 2 && n <= 4) {
    return returnWithNumber ? `${number} ${two}` : two;
  }
  return returnWithNumber ? `${number} ${five}` : five;
}

/* eslint-disable */

const ISO = {
  971: { name: 'AFGHANISTAN	Afghani', value: 'AFN' },
  '008': { name: 'ALBANIA	Lek', value: 'ALL' },
  '012': { name: 'ALGERIA	Algerian Dinar', value: 'DZD' },
  978: { name: 'ANDORRA	Euro', value: 'EUR', symbol: '€' },
  973: { name: 'ANGOLA	Kwanza', value: 'AOA' },
  '032': { name: 'ARGENTINA	Argentine Peso', value: 'ARS' },
  '051': { name: 'ARMENIA	Armenian Dram', value: 'AMD' },
  533: { name: 'ARUBA	Aruban Florin', value: 'AWG' },
  944: { name: 'AZERBAIJAN	Azerbaijanian Manat', value: 'AZN' },
  '044': { name: 'BAHAMAS (THE)	Bahamian Dollar', value: 'BSD' },
  '048': { name: 'BAHRAIN	Bahraini Dinar', value: 'BHD' },
  '050': { name: 'BANGLADESH	Taka', value: 'BDT' },
  '052': { name: 'BARBADOS	Barbados Dollar', value: 'BBD' },
  974: { name: 'BELARUS	Belarussian Ruble', value: 'BYR' },
  '084': { name: 'BELIZE	Belize Dollar', value: 'BZD' },
  '060': { name: 'BERMUDA	Bermudian Dollar', value: 'BMD' },
  '064': { name: 'BHUTAN	Ngultrum', value: 'BTN' },
  '068': { name: 'BOLIVIA (PLURINATIONAL STATE OF)	Boliviano', value: 'BOB' },
  984: { name: 'BOLIVIA (PLURINATIONAL STATE OF)	Mvdol', value: 'BOV' },
  977: { name: 'BOSNIA AND HERZEGOVINA	Convertible Mark', value: 'BAM' },
  '072': { name: 'BOTSWANA	Pula', value: 'BWP' },
  986: { name: 'BRAZIL	Brazilian Real', value: 'BRL' },
  '096': { name: 'BRUNEI DARUSSALAM	Brunei Dollar', value: 'BND' },
  975: { name: 'BULGARIA	Bulgarian Lev', value: 'BGN' },
  108: { name: 'BURUNDI	Burundi Franc', value: 'BIF' },
  132: { name: 'CABO VERDE	Cabo Verde Escudo', value: 'CVE' },
  116: { name: 'CAMBODIA	Riel', value: 'KHR' },
  124: { name: 'CANADA	Canadian Dollar', value: 'CAD' },
  136: { name: 'CAYMAN ISLANDS (THE)	Cayman Islands Dollar', value: 'KYD' },
  990: { name: 'CHILE	Unidad de Fomento', value: 'CLF' },
  152: { name: 'CHILE	Chilean Peso', value: 'CLP' },
  156: { name: 'CHINA	Yuan Renminbi', value: 'CNY' },
  170: { name: 'COLOMBIA	Colombian Peso', value: 'COP' },
  970: { name: 'COLOMBIA	Unidad de Valor Real', value: 'COU' },
  174: { name: 'COMOROS (THE)	Comoro Franc', value: 'KMF' },
  976: { name: 'CONGO (THE DEMOCRATIC REPUBLIC OF THE)	Congolese Franc', value: 'CDF' },
  188: { name: 'COSTA RICA	Costa Rican Colon', value: 'CRC' },
  191: { name: 'CROATIA	Kuna', value: 'HRK' },
  931: { name: 'CUBA	Peso Convertible', value: 'CUC' },
  192: { name: 'CUBA	Cuban Peso', value: 'CUP' },
  203: { name: 'CZECH REPUBLIC (THE)	Czech Koruna', value: 'CZK' },
  262: { name: 'DJIBOUTI	Djibouti Franc', value: 'DJF' },
  214: { name: 'DOMINICAN REPUBLIC (THE)	Dominican Peso', value: 'DOP' },
  818: { name: 'EGYPT	Egyptian Pound', value: 'EGP' },
  222: { name: 'EL SALVADOR	El Salvador Colon', value: 'SVC' },
  232: { name: 'ERITREA	Nakfa', value: 'ERN' },
  230: { name: 'ETHIOPIA	Ethiopian Birr', value: 'ETB' },
  238: { name: 'FALKLAND ISLANDS (THE) [MALVINAS]	Falkland Islands Pound', value: 'FKP' },
  242: { name: 'FIJI	Fiji Dollar', value: 'FJD' },
  950: { name: 'GABON	CFA Franc BEAC', value: 'XAF' },
  270: { name: 'GAMBIA (THE)	Dalasi', value: 'GMD' },
  981: { name: 'GEORGIA	Lari', value: 'GEL' },
  936: { name: 'GHANA	Ghana Cedi', value: 'GHS' },
  292: { name: 'GIBRALTAR	Gibraltar Pound', value: 'GIP' },
  208: { name: 'GREENLAND	Danish Krone', value: 'DKK' },
  320: { name: 'GUATEMALA	Quetzal', value: 'GTQ' },
  324: { name: 'GUINEA	Guinea Franc', value: 'GNF' },
  328: { name: 'GUYANA	Guyana Dollar', value: 'GYD' },
  332: { name: 'HAITI	Gourde', value: 'HTG' },
  340: { name: 'HONDURAS	Lempira', value: 'HNL' },
  344: { name: 'HONG KONG	Hong Kong Dollar', value: 'HKD' },
  348: { name: 'HUNGARY	Forint', value: 'HUF' },
  352: { name: 'ICELAND	Iceland Krona', value: 'ISK' },
  356: { name: 'INDIA	Indian Rupee', value: 'INR' },
  360: { name: 'INDONESIA	Rupiah', value: 'IDR' },
  960: { name: 'INTERNATIONAL MONETARY FUND (IMF) 	SDR (Special Drawing Right)', value: 'XDR' },
  364: { name: 'IRAN (ISLAMIC REPUBLIC OF)	Iranian Rial', value: 'IRR' },
  368: { name: 'IRAQ	Iraqi Dinar', value: 'IQD' },
  376: { name: 'ISRAEL	New Israeli Sheqel', value: 'ILS' },
  388: { name: 'JAMAICA	Jamaican Dollar', value: 'JMD' },
  392: { name: 'JAPAN	Yen', value: 'JPY' },
  400: { name: 'JORDAN	Jordanian Dinar', value: 'JOD' },
  398: { name: 'KAZAKHSTAN	Tenge', value: 'KZT' },
  404: { name: 'KENYA	Kenyan Shilling', value: 'KES' },
  408: { name: 'KOREA (THE DEMOCRATIC PEOPLE’S REPUBLIC OF)	North Korean Won', value: 'KPW' },
  410: { name: 'KOREA (THE REPUBLIC OF)	Won', value: 'KRW' },
  414: { name: 'KUWAIT	Kuwaiti Dinar', value: 'KWD' },
  417: { name: 'KYRGYZSTAN	Som', value: 'KGS' },
  418: { name: 'LAO PEOPLE’S DEMOCRATIC REPUBLIC (THE)	Kip', value: 'LAK' },
  422: { name: 'LEBANON	Lebanese Pound', value: 'LBP' },
  426: { name: 'LESOTHO	Loti', value: 'LSL' },
  430: { name: 'LIBERIA	Liberian Dollar', value: 'LRD' },
  434: { name: 'LIBYA	Libyan Dinar', value: 'LYD' },
  446: { name: 'MACAO	Pataca', value: 'MOP' },
  807: { name: 'MACEDONIA (THE FORMER YUGOSLAV REPUBLIC OF)	Denar', value: 'MKD' },
  969: { name: 'MADAGASCAR	Malagasy Ariary', value: 'MGA' },
  454: { name: 'MALAWI	Kwacha', value: 'MWK' },
  458: { name: 'MALAYSIA	Malaysian Ringgit', value: 'MYR' },
  462: { name: 'MALDIVES	Rufiyaa', value: 'MVR' },
  478: { name: 'MAURITANIA	Ouguiya', value: 'MRO' },
  480: { name: 'MAURITIUS	Mauritius Rupee', value: 'MUR' },
  965: { name: 'MEMBER COUNTRIES OF THE AFRICAN DEVELOPMENT BANK GROUP	ADB Unit of Account', value: 'XUA' },
  484: { name: 'MEXICO	Mexican Peso', value: 'MXN' },
  979: { name: 'MEXICO	Mexican Unidad de Inversion (UDI)', value: 'MXV' },
  498: { name: 'MOLDOVA (THE REPUBLIC OF)	Moldovan Leu', value: 'MDL' },
  496: { name: 'MONGOLIA	Tugrik', value: 'MNT' },
  943: { name: 'MOZAMBIQUE	Mozambique Metical', value: 'MZN' },
  104: { name: 'MYANMAR	Kyat', value: 'MMK' },
  516: { name: 'NAMIBIA	Namibia Dollar', value: 'NAD' },
  524: { name: 'NEPAL	Nepalese Rupee', value: 'NPR' },
  558: { name: 'NICARAGUA	Cordoba Oro', value: 'NIO' },
  566: { name: 'NIGERIA	Naira', value: 'NGN' },
  512: { name: 'OMAN	Rial Omani', value: 'OMR' },
  586: { name: 'PAKISTAN	Pakistan Rupee', value: 'PKR' },
  590: { name: 'PANAMA	Balboa', value: 'PAB' },
  598: { name: 'PAPUA NEW GUINEA	Kina', value: 'PGK' },
  600: { name: 'PARAGUAY	Guarani', value: 'PYG' },
  604: { name: 'PERU	Nuevo Sol', value: 'PEN' },
  608: { name: 'PHILIPPINES (THE)	Philippine Peso', value: 'PHP' },
  985: { name: 'POLAND	Zloty', value: 'PLN' },
  634: { name: 'QATAR	Qatari Rial', value: 'QAR' },
  946: { name: 'ROMANIA	Romanian Leu', value: 'RON' },
  643: { name: 'RUSSIAN FEDERATION (THE)	Russian Ruble', value: 'RUB', symbol: '₽' },
  646: { name: 'RWANDA	Rwanda Franc', value: 'RWF' },
  654: { name: 'SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA	Saint Helena Pound', value: 'SHP' },
  951: { name: 'SAINT VINCENT AND THE GRENADINES	East Caribbean Dollar', value: 'XCD' },
  882: { name: 'SAMOA	Tala', value: 'WST' },
  678: { name: 'SAO TOME AND PRINCIPE	Dobra', value: 'STD' },
  682: { name: 'SAUDI ARABIA	Saudi Riyal', value: 'SAR' },
  941: { name: 'SERBIA	Serbian Dinar', value: 'RSD' },
  690: { name: 'SEYCHELLES	Seychelles Rupee', value: 'SCR' },
  694: { name: 'SIERRA LEONE	Leone', value: 'SLL' },
  702: { name: 'SINGAPORE	Singapore Dollar', value: 'SGD' },
  532: { name: 'SINT MAARTEN (DUTCH PART)	Netherlands Antillean Guilder', value: 'ANG' },
  '090': { name: 'SOLOMON ISLANDS	Solomon Islands Dollar', value: 'SBD' },
  706: { name: 'SOMALIA	Somali Shilling', value: 'SOS' },
  710: { name: 'SOUTH AFRICA	Rand', value: 'ZAR' },
  728: { name: 'SOUTH SUDAN	South Sudanese Pound', value: 'SSP' },
  144: { name: 'SRI LANKA	Sri Lanka Rupee', value: 'LKR' },
  938: { name: 'SUDAN (THE)	Sudanese Pound', value: 'SDG' },
  968: { name: 'SURINAME	Surinam Dollar', value: 'SRD' },
  578: { name: 'SVALBARD AND JAN MAYEN	Norwegian Krone', value: 'NOK' },
  748: { name: 'SWAZILAND	Lilangeni', value: 'SZL' },
  752: { name: 'SWEDEN	Swedish Krona', value: 'SEK' },
  947: { name: 'SWITZERLAND	WIR Euro', value: 'CHE' },
  756: { name: 'SWITZERLAND	Swiss Franc', value: 'CHF' },
  948: { name: 'SWITZERLAND	WIR Franc', value: 'CHW' },
  760: { name: 'SYRIAN ARAB REPUBLIC	Syrian Pound', value: 'SYP' },
  901: { name: 'TAIWAN (PROVINCE OF CHINA)	New Taiwan Dollar', value: 'TWD' },
  972: { name: 'TAJIKISTAN	Somoni', value: 'TJS' },
  834: { name: 'TANZANIA, UNITED REPUBLIC OF	Tanzanian Shilling', value: 'TZS' },
  764: { name: 'THAILAND	Baht', value: 'THB' },
  952: { name: 'TOGO	CFA Franc BCEAO', value: 'XOF' },
  554: { name: 'TOKELAU	New Zealand Dollar', value: 'NZD' },
  776: { name: 'TONGA	Pa’anga', value: 'TOP' },
  780: { name: 'TRINIDAD AND TOBAGO	Trinidad and Tobago Dollar', value: 'TTD' },
  788: { name: 'TUNISIA	Tunisian Dinar', value: 'TND' },
  949: { name: 'TURKEY	Turkish Lira', value: 'TRY', symbol: '₺' },
  934: { name: 'TURKMENISTAN	Turkmenistan New Manat', value: 'TMT' },
  '036': { name: 'TUVALU	Australian Dollar', value: 'AUD' },
  800: { name: 'UGANDA	Uganda Shilling', value: 'UGX' },
  980: { name: 'UKRAINE	Hryvnia', value: 'UAH' },
  784: { name: 'UNITED ARAB EMIRATES (THE)	UAE Dirham', value: 'AED' },
  826: { name: 'UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND (THE)	Pound Sterling', value: 'GBP' },
  997: { name: 'UNITED STATES OF AMERICA (THE)	US Dollar (Next day)', value: 'USN' },
  940: { name: 'URUGUAY	Uruguay Peso en Unidades Indexadas (URUIURUI)', value: 'UYI' },
  858: { name: 'URUGUAY	Peso Uruguayo', value: 'UYU' },
  860: { name: 'UZBEKISTAN	Uzbekistan Sum', value: 'UZS' },
  548: { name: 'VANUATU	Vatu', value: 'VUV' },
  937: { name: 'VENEZUELA (BOLIVARIAN REPUBLIC OF)	Bolivar', value: 'VEF' },
  704: { name: 'VIET NAM	Dong', value: 'VND' },
  840: { name: 'VIRGIN ISLANDS (U.S.)	US Dollar', value: 'USD', symbol: '$' },
  953: { name: 'WALLIS AND FUTUNA	CFP Franc', value: 'XPF' },
  504: { name: 'WESTERN SAHARA	Moroccan Dirham', value: 'MAD' },
  886: { name: 'YEMEN	Yemeni Rial', value: 'YER' },
  967: { name: 'ZAMBIA	Zambian Kwacha', value: 'ZMW' },
  932: { name: 'ZIMBABWE	Zimbabwe Dollar', value: 'ZWL' },
  955: { name: 'ZZ01_Bond Markets Unit European_EURCO	Bond Markets Unit European Composite Unit (EURCO)', value: 'XBA' },
  956: { name: 'ZZ02_Bond Markets Unit European_EMU-6	Bond Markets Unit European Monetary Unit (E.M.U.-6)', value: 'XBB' },
  957: { name: 'ZZ03_Bond Markets Unit European_EUA-9	Bond Markets Unit European Unit of Account 9 (E.U.A.-9)', value: 'XBC' },
  958: { name: 'ZZ04_Bond Markets Unit European_EUA-17	Bond Markets Unit European Unit of Account 17 (E.U.A.-17)', value: 'XBD' },
  963: { name: 'ZZ06_Testing_Code	Codes specifically reserved for testing purposes', value: 'XTS' },
  999: { name: 'ZZ07_No_Currency	The codes assigned for transactions where no currency is involved', value: 'XXX' },
  959: { name: 'ZZ08_Gold	Gold', value: 'XAU' },
  964: { name: 'ZZ09_Palladium	Palladium', value: 'XPD' },
  962: { name: 'ZZ10_Platinum	Platinum', value: 'XPT' },
  961: { name: 'ZZ11_Silver	Silver', value: 'XAG' },
};

export function currencyIntToIso(input, symbol) {
  return ISO[input] ? ((symbol && ISO[input].symbol) ? ISO[input].symbol : ISO[input].value) : input;
}

// .toLocaleString('ru-RU').replace(/\./g, ',')


// TODO: polyfill https://github.com/andyearnshaw/Intl.js/
export function formatMoneyWithCurrency(amount, { currency, exponent, digitsAfterComma = 2, withSpaces = true, symbol = true, withDivision = true }) {
  const divider = Math.pow(10, exponent);
  const finalAmount = withDivision ? (amount / divider) : amount;

  if (withSpaces) {
    let formatter;
    const options = {
      style: 'currency',
      currency: currencyIntToIso(currency, false),
      minimumFractionDigits: digitsAfterComma,
      currencyDisplay: 'narrowSymbol',
    };

    try {
      // TODO: make 'ru' changeable by config
      formatter = new Intl.NumberFormat('ru', options);
    } catch (e) {
      // safari 14 doesn't support
      options.currencyDisplay = 'symbol';
      formatter = new Intl.NumberFormat('ru', options);
    }

    return `${formatter.format(finalAmount)}`;
  }

  return `${finalAmount} ${currencyIntToIso(currency, true)}`;
}

/* eslint-enable */
