export const errorCodeToMessageTranslationKey = {
  0: 'internet_error',
  500: 'internal_server',
  1000: 'session_not_found',
  1001: 'form_generation_error',
  1002: 'calc_fee_error',
  1003: 'init_payment_error',
  1004: 'get_operator_error',
  1005: 'get_payment_status_error',
  1006: 'get_payment_status_error',
  1007: 'save_email_error',
  1008: 'email_not_found',
  1009: 'email_not_send',
  1010: 'sms_not_sent',
  1011: 'invalid_phone_number',
  1012: 'limit_of_retries_of_sms_sends_is_exceeded',
  1013: 'wrong_retries_interval',
  1014: 'confirmation_code_not_found',
  1015: 'limit_of_retries_of_code_checks_is_exceeded',
  1016: 'expire_time',
  1017: 'wrong_code',
  1404: 'invoice_not_found',
  1500: 'app_error_with_custom_description',
  2404: 'qr_not_found',
  2405: 'invoice_by_qr_code_not_found',
  2001: 'too_much_amount_for_available_methods',
};
