import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import moment from 'moment';
import Color from 'color';
import { getDeliveryPriceAmount } from '../../utils/delivery';
import { getContrastColor } from '../../utils/detectContrastColor';
import { formatMoneyWithCurrency, getLink, getNoun } from '../../utils/format';

import './infoBlock.scss';

import dotsBlack from '../../assets/img/dots-black.png';
import dotsWhite from '../../assets/img/dots-white.png';

import ArrowDown from '../../assets/svg/arrow-down.svg';
import CalendarIcon from '../../assets/svg/calendar-white.svg';
import SupportIcon from '../../assets/svg/message-white.svg';
import AliveFormLabel from '../common/AliveFormLabel';
import { ViewportSize } from '../viewport/Viewport';
import { isIFrame } from '../../utils/checkIframe';

const getProtocolLessLink = link => link.replace(/(^\w+:|^)\/\//, '');

const checkIfWhite = (color, bgColor) => color === '#ffffff' || color === '#ffff' || color === '#fff' || color === 'white' || color.replace(/\s/g, '').indexOf('255,255,255') > -1 || getContrastColor(bgColor);

const getDots = (color, bgColor) => (checkIfWhite(color, bgColor) ? dotsWhite : dotsBlack);
const getRgbaTextColorWithZeroAlpha = (color, bgColor) => (checkIfWhite(color, bgColor) ? 'rgba(255,255,255,0)' : 'rgba(0,0,0,0)');

const Block = styled.div`
  background: ${props => props.theme.primaryBgColor};
  box-shadow: 14px 11px 25px 0 ${(props) => {
    const lightness = Color(props.theme.primaryBgColor).hsl().object().l;
    return Color(props.theme.primaryBgColor).rotate(-1).desaturate(0.586).lightness(lightness - 32).fade(.58).round().rgb();
  }};

  @media (max-width: 879px) {
    box-shadow: none;
  }

  .amount-label {
    color: ${props => props.theme.primaryTextColor};
  }

  .commission-row {
    color: ${props => props.theme.primaryTextColor};
    background: ${props => props.theme.primaryBgColor};
  }

  .commission-label {
    &::after {
      background: ${props => `url(${getDots(props.theme.primaryTextColor, props.theme.primaryBgColor)}) 0 0 repeat-x`};
    }
  }

  .hr-with-bg-color {
    background-image: ${props => `linear-gradient(to left, ${getRgbaTextColorWithZeroAlpha(props.theme.primaryTextColor, props.theme.primaryBgColor)}, ${props.theme.primaryTextColor} 15%, ${props.theme.primaryTextColor})`};

    @media (max-width: 879px) {
      background-image: ${props => `radial-gradient(${props.theme.primaryTextColor}, ${props.theme.primaryTextColor} 15%, ${getRgbaTextColorWithZeroAlpha(props.theme.primaryTextColor, props.theme.primaryBgColor)})`};
    }
  }

  .alive-time {
    color: ${props => props.theme.primaryTextColor};
  }

  .product-name-and-url,
  .product-url,
  .period {
    color: ${props => props.theme.primaryTextColor};
  }

  .colored-calendar-icon {
    rect {
      stroke: ${props => props.theme.primaryTextColor};
    }

    path {
      fill: ${props => props.theme.primaryTextColor};
    }
  }

  .agreement-block {
    color: ${props => props.theme.primaryTextColor};

    .agreement-link {
      color: ${props => props.theme.primaryTextColor};
      border-bottom: 1px solid ${props => Color(props.theme.primaryTextColor).fade(0.8).string()};

      &:hover {
        border-color: ${props => Color(props.theme.primaryTextColor).fade(0.2).string()};
      }
    }
  }

  .colored-attention-icon {
    path {
      fill: ${props => props.theme.primaryTextColor};
    }
  }

  .support-title,
  .support-all-day-working,
  .support-will-contact-you {
    color: ${props => props.theme.primaryTextColor};
  }

  .client-support-block {
    color: ${props => props.theme.primaryTextColor};

    .support-icon-wrap {
      &:before {
        background: ${props => props.theme.primaryTextColor};
      }

      .support-icon {
        path {
          fill: ${props => props.theme.primaryTextColor};
          stroke: ${props => props.theme.primaryTextColor};
        }
      }
    }
  }

  .paycafe-close-button:before,
  .paycafe-close-button:after {
    background-color: ${props => props.theme.primaryTextColor};
  }
`;

const getPeriodTextValue = (periodMs, t) => {
  const humanized = moment.duration(periodMs).humanize().replace(' ', '&nbsp;');
  const r = /\d+/g;
  const arrOfNumbers = humanized.match(r);
  const prefix = arrOfNumbers ? getNoun(arrOfNumbers[arrOfNumbers.length - 1], t('infoBlock.everyOne'), t('infoBlock.everyTwo'), t('infoBlock.everyFive')) : t('infoBlock.everyOne');

  return `${prefix}&nbsp;${humanized}`;
};

const stepKeysWithoutAgreement = ['info', 'recurrent'];

class InfoBlock extends React.PureComponent {
  static propTypes = {
    app: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    formData: PropTypes.object.isRequired,
    sessionId: PropTypes.string.isRequired,
    tabletExpanded: PropTypes.bool,
    onTabletExpand: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    viewport: PropTypes.object.isRequired,
  };

  static defaultProps = {
    tabletExpanded: false,
  };

  constructor(props) {
    super(props);

    this.infoBlock = React.createRef();
    this.tabletCollapsingBlock = React.createRef();
  }

  onSupportClick = () => {
    const { sessionId, history, location } = this.props;

    const supportUrl = `/${sessionId}/support`;

    if (location.pathname !== supportUrl) {
      history.push(supportUrl);
    }
  };

  onTabletExpandClick = () => {
    const { tabletExpanded } = this.props;
    const collapsingEl = this.tabletCollapsingBlock.current;

    if (!tabletExpanded) {
      collapsingEl.style.display = 'block';
    }

    const translateY = collapsingEl.clientHeight;

    this.props.onTabletExpand(translateY)
      .then((newTabletExpanded) => {
        if (!newTabletExpanded) {
          collapsingEl.style.display = 'none';
        }
      });
  };

  handlePaycafeClose = () => {
    const opener = window.opener || window.parent;
    opener.postMessage({ type: 'widget-CLOSE-BUTTON' }, '*');
    // closeEvent();
  };

  render() {
    const {
      app: { product, endTime, linkAlreadyPaid, steps },
      data: { amountAndProductShown, txStarted, currentStepIndex, payment, payment: { currency, exponent } },
      formData,
      tabletExpanded, sessionId, location, t, viewport,
    } = this.props;

    const rawAmountWithDelivery = payment.amount + getDeliveryPriceAmount(formData.delivery, payment);
    const amount = typeof payment.amount !== 'undefined' ? formatMoneyWithCurrency(rawAmountWithDelivery, { currency, exponent }) : '';
    const amountWithCommission = rawAmountWithDelivery + (payment.commission >= 0 ? payment.commission : 0);
    const commission = payment.commission >= 0 ? formatMoneyWithCurrency(payment.commission, { currency, exponent }) : '';
    const totalToPay = commission ? formatMoneyWithCurrency(amountWithCommission, { currency, exponent }) : '';
    // const isStepWithAgreement = steps[currentStepIndex] && stepKeysWithoutAgreement.indexOf(steps[currentStepIndex].key) === -1;
    // const isBankCardMethod = location.pathname.includes('bank-card');
    // const isMobilePayMethod = location.pathname.includes('mobile-pay');

    // const agreementShown = (isStepWithAgreement && !txStarted) && (isMobilePayMethod || (isBankCardMethod && payment.commission >= 0));
    // const mobilePayAgreementUrl = 'http://ribank.ru/wp-content/uploads/Oferta-Mobilnyj-platezh-Teko-21012020.pdf'; //'http://ribank.ru/wp-content/uploads/oferta_BOS_TEKO.pdf'
    // const bankCardAgreementUrl = `/api/bank-card-agreement?commission=${payment.commission}&amount=${amountWithCommission}&product=${product.id}`;
    // const agreementUrl = isBankCardMethod ? bankCardAgreementUrl : mobilePayAgreementUrl;

    const supportUrl = `/${sessionId}/support`;

    return (
      <Block className="info-block" ref={this.infoBlock}>
        {location.pathname !== supportUrl ? (
          <React.Fragment>
            {amountAndProductShown && (
              <React.Fragment>
                <div className={classnames('tablet-fixed-block', { noCommissionMobile: isIFrame() && !commission && viewport.size === ViewportSize.MOBILE })}>
                  {viewport.size !== ViewportSize.DESKTOP && (product.name || product.url) && (
                    <div className={classnames('expand-btn', { nearCommission: !!commission, active: tabletExpanded, shown: !!amount })} onClick={this.onTabletExpandClick}>
                      <ArrowDown className="expand-icon" />
                    </div>
                  )}
                  {!!amount && (
                    <div className="amount-label" key="amount">{amount}</div>
                  )}
                  {!!commission && (
                    <div className="commission-and-sum" key="commission-and-sum">
                      <div className="commission-row">
                        <div className="commission-label">
                          <span className="commission-label-inner">{t('infoBlock.commission')}</span>
                        </div>
                        <div className="commission-value">
                          <div className="commission-value-inner">{commission}</div>
                        </div>
                      </div>
                      <div className="commission-row">
                        <div className="commission-label">
                          <span className="commission-label-inner">{t('infoBlock.totalToPay')}</span>
                        </div>
                        <div className="commission-value">
                          <div className="commission-value-inner">{totalToPay}</div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className={classnames('tablet-collapsing-block', { tabletDefaultShown: !amount })} key="tablet-collapsing-block" ref={this.tabletCollapsingBlock}>
                  {!!amount && (
                    <hr className="hr-with-bg-color" key="hr-amount" />
                  )}

                  {endTime && !linkAlreadyPaid && <AliveFormLabel endTime={endTime} />}

                  {(product.name || product.url) && (
                    <div className="product-name-and-url" key="product-name-and-url">
                      <div className="product-name">
                        {product.name}
                      </div>
                      <a className="product-url" href={getLink(product.url)} target="_blank" rel="noreferrer noopener">
                        {getProtocolLessLink(product.url)}
                      </a>
                    </div>
                  )}
                  {!!payment.period && (
                    <div className="period" key="period">
                      <CalendarIcon className="colored-calendar-icon" />
                      <div className="period-label">{t('infoBlock.periodLabel')}</div>
                      <div className="period-value" dangerouslySetInnerHTML={{ __html: t('infoBlock.periodValue', { period: getPeriodTextValue(payment.period, t) }) }} />
                    </div>
                  )}
                  {/*{viewport.size === ViewportSize.DESKTOP && agreementShown && (*/}
                  {/*  <div className="agreement-block">*/}
                  {/*    <AttentionIcon className="colored-attention-icon" />*/}
                  {/*    <p>*/}
                  {/*      <span dangerouslySetInnerHTML={{ __html: t('infoBlock.you_agree') }} />*/}
                  {/*      <a className="agreement-link" href={agreementUrl} target="_blank" rel="noreferrer noopener" dangerouslySetInnerHTML={{ __html: t('infoBlock.terms_and_conditions') }} />*/}
                  {/*    </p>*/}
                  {/*  </div>*/}
                  {/*)}*/}
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="support-title" dangerouslySetInnerHTML={{ __html: t('support.sidebar.title') }} />
            <div className="support-all-day-working" dangerouslySetInnerHTML={{ __html: t('support.sidebar.work_all_day') }} />
            <div className="support-will-contact-you" dangerouslySetInnerHTML={{ __html: t('support.sidebar.support_will_contact') }} />
          </React.Fragment>
        )}
        {viewport.size === ViewportSize.DESKTOP && (
          <div className="client-support-block" onClick={this.onSupportClick}>
            <div className="support-text" dangerouslySetInnerHTML={{ __html: t('infoBlock.support') }} />
            <div className="support-icon-wrap">
              <SupportIcon className="support-icon" />
            </div>
          </div>
        )}
        {viewport.size === ViewportSize.MOBILE && window.OPENED_BY_WIDGET && (
          <div onClick={this.handlePaycafeClose} className="paycafe-close-button" />
        )}
      </Block>
    );
  }
}

export default withTranslation()(InfoBlock);
