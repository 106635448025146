import React from 'react';
import { Route } from 'react-router-dom';

function AppRoute({ component: Component, paramCausingRemount, pageProps, ...rest }) {
  if (paramCausingRemount) {
    return (
      <Route {...rest} render={props => (<Component key={props.match.params[paramCausingRemount]} {...pageProps} {...props} />)} />
    );
  }

  return (
    <Route {...rest} render={props => (<Component {...pageProps} {...props} />)} />
  );
}

export default AppRoute;
