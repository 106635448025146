import client from '../utils/api-client';
import { GET_FORM, GET_PAYMENT_DATA, INIT_DATA, SET_LINK_EXPIRED } from '../constants/actionTypes';

export function getForm({ sessionId }) {
  return (dispatch, getState) => {
    const { app } = getState();

    if (app.loaded) {
      // SSR: data loaded
      dispatch({ type: INIT_DATA, payload: app, meta: { sessionId } });

      return Promise.resolve();
    }

    return client({ path: `/get-form/${sessionId}`, method: 'GET' })
      .then((response) => {
        const payload = { ...response };
        if (payload.txExtra) {
          dispatch({ type: GET_PAYMENT_DATA, payload: { extra: payload.txExtra } });
          delete payload.txExtra;
        }
        dispatch({ type: GET_FORM, payload });
        dispatch({ type: INIT_DATA, payload, meta: { sessionId } });
      });
  };
}

export function setLinkExpired() {
  return dispatch => dispatch({ type: SET_LINK_EXPIRED });
}
