import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

export const CardTokenizatorPaymentContext = React.createContext(null);

const CardTokenizatorPaymentManager = ({ children }) => {
  const [payment, setPayment] = useState({
    partnerId: null,
    paymentSession: null,
  });

  const setPaymentHandler = useCallback((partnerId, paymentSession) => {
    setPayment({
      partnerId,
      paymentSession,
    });
  }, []);

  const value = useMemo(() => ({
    payment,
    setPayment: setPaymentHandler,
  }), [payment]);


  return (
    <CardTokenizatorPaymentContext.Provider value={value}>
      {children}
    </CardTokenizatorPaymentContext.Provider>
  );
};

CardTokenizatorPaymentManager.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CardTokenizatorPaymentManager;
