import {
  CALC_FEE,
  INIT_PAYMENT,
  CHANGE_CURRENT_STEP,
  INIT_DATA,
  UPDATE_STORE_DATA_FIELD,
  GET_PAYMENT_STATUS,
  GET_PAYMENT_DATA,
  GET_EMAIL,
  SAVE_EMAIL,
  SEND_EMAIL,
  CREATE_ISSUE,
  RESET_TX_DATA,
  CHECK_CONFIRMATION_CODE, SEND_CONFIRMATION_CODE,
} from '../constants/actionTypes';

const initialState = {
  currentStepIndex: 0,
  payment: {
    exponent: 2,
    amount: 1200,
    currency: 643,
    isRegular: false,
    mutable: true,
    period: 0,
    maxAmount: 15000000,
  },
  sbpQrs: {},
  extra: {
    delivery: {
      fullName: 'Татьянко Тимур Александрович',
      contactPhone: '79851991944',
      // editIsDisabled: true,
    },
  },
  // info: {
  //   repeatDeliveryAddress: false,
  //   region: '',
  //   city: '',
  //   index: '',
  //   address: '',
  //   comment: '',
  //   firstName: '',
  //   lastName: '',
  //   email: '',
  // },
};

function setField(keys, obj, value) {
  if (keys.length > 1) {
    setField(keys.slice(1), obj[keys[0]], value);
  } else {
    obj[keys[0]] = value;
  }
}

export default function data(state = initialState, action) {
  switch (action.type) {
    case INIT_DATA:
      const payment = { ...action.payload.payment };

      // if (action.payload.payment.mutable) {
      //   delete payment.amount;
      //   delete payment.period;
      // }

      return {
        ...state,
        payment,
      };

    case CALC_FEE:
      return {
        ...state,
        payment: {
          ...state.payment,
          commission: action.payload.payment.commission,
        },
      };

    case INIT_PAYMENT:
      return {
        ...state,
        ...action.payload,
        extra: {
          ...state.extra,
          ...action.meta.extra,
        },
        noFieldsMethod: action.meta.noFieldsMethod,
        txStarted: !action.meta.isSbp,
      };

    case SEND_CONFIRMATION_CODE:
      return {
        ...state,
        paymentDataToConfirm: {
          ...action.meta,
          salt: action.payload.salt,
        },
        confirmationSentCount: state.confirmationSentCount ? ++state.confirmationSentCount : 1,
        confirmationCodeSentAt: Date.now(),
      };

    case CHECK_CONFIRMATION_CODE:
      return {
        ...state,
        ...action.payload,
        txStarted: true,
      };

    case GET_PAYMENT_STATUS:
      const { payment: notNeeded, ...restPayload } = action.payload;

      if (action.meta.isSbp) {
        return {
          ...state,
          ...restPayload,
        };
      }

      return {
        ...state,
        ...restPayload,
      };

    case GET_PAYMENT_DATA:
      return {
        ...state,
        ...action.payload,
        payment: {
          ...state.payment,
          ...action.payload.payment,
          amount: typeof action.payload.extra.paymentAmount !== 'undefined' ? action.payload.extra.paymentAmount : action.payload.payment.amount,
        },
      };

    case GET_EMAIL:
      return {
        ...state,
        ...action.payload,
      };

    case SAVE_EMAIL:
    case SEND_EMAIL:
      return {
        ...state,
        ...action.payload,
        emailSaved: true,
      };

    case CREATE_ISSUE:
      return {
        ...state,
        issue: {
          ...action.payload,
        },
      };

    case CHANGE_CURRENT_STEP:
      return {
        ...state,
        currentStepIndex: action.payload.currentStepIndex,
      };

    case UPDATE_STORE_DATA_FIELD:
      const { field, value } = action.payload;

      if (typeof field !== 'string') return state;

      const keysArr = field.split('.');
      const fKey = keysArr[0];
      const fKeyVal = state[fKey];
      const newState = { [fKey]: typeof fKeyVal === 'object' ? { ...fKeyVal } : fKeyVal };

      setField(keysArr, newState, value);

      return {
        ...state,
        ...newState,
      };

    case RESET_TX_DATA:
      const stateWithoutTx = {
        ...state,
        payment: {
          ...state.payment,
          commission: -1,
        },
      };

      delete stateWithoutTx.noFieldsMethod;
      delete stateWithoutTx.txStarted;
      delete stateWithoutTx.tx;

      return stateWithoutTx;

    default:
      return state;
  }
}
