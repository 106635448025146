import {
  SAVE_DELIVERY,
  SAVE_FORM_DATA,
  SAVE_FIELDS_BY_METHOD_FORM_DATA,
  UPDATE_RECEIPT_FIELD, GET_PAYMENT_DATA,
} from '../constants/actionTypes';

const initialState = {
  delivery: null,
  byMethodKey: {},
  lastModified: '',
  getReceipt: true,
  receiptEmail: null,
  _receiptEmail: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_DELIVERY:
      return {
        ...state,
        delivery: action.payload,
      };

    case GET_PAYMENT_DATA:
      if (action.payload.extra.delivery) {
        return {
          ...state,
          delivery: action.payload.extra.delivery || null,
        };
      }
      return state;

    case SAVE_FORM_DATA:
      const { method, data } = action.payload;

      return {
        ...state,
        byMethodKey: {
          ...state.byMethodKey,
          [method]: data,
        },
        lastModified: method,
      };

    case SAVE_FIELDS_BY_METHOD_FORM_DATA:
      return {
        ...state,
        byMethodKey: {
          ...state.byMethodKey,
          [action.payload.method]: action.payload.data,
        },
        lastModified: '',
      };

    case UPDATE_RECEIPT_FIELD:
      const { fieldName, value } = action.payload;
      if (fieldName !== 'getReceipt' && fieldName !== 'receiptEmail' && fieldName !== '_receiptEmail') return state;

      return {
        ...state,
        [fieldName]: value,
      };

    default:
      return {
        ...initialState,
        ...state,
      };
  }
}
