import i18next from 'i18next';
import Cache from 'i18next-localstorage-cache';
import Backend from './i18next-webpack-backend';

i18next
  .use(Backend)
  .use(Cache)
  // .use(LanguageDetector)
  .init({
    lng: 'ru', // TODO: lang detection
    fallbackLng: 'ru',

    // have a common namespace used around the full app
    ns: ['translation'],
    defaultNS: 'translation',

    debug: process.env.NODE_ENV === 'development',

    cache: {
      enabled: true, // process.env.NODE_ENV === 'production',
      expirationTime: 24 * 60 * 60 * 1000, // 1 day,
      versions: {
        en: '2017.04.19',
      },
    },

    interpolation: {
      escapeValue: false, // not needed for react!!
    },

    react: {
      wait: true,
    },
  });

export default i18next;
