/* eslint-disable */

function hexToRgb(hexVal) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const hex = hexVal.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    R: parseInt(result[1], 16),
    G: parseInt(result[2], 16),
    B: parseInt(result[3], 16),
  } : null;
}

export function getContrastColor(colorParam) {
  let color = colorParam;

  if (color.indexOf('rgb') < 0) {
    color = hexToRgb(color);
  } else {
    const rgbArr = color.replace(/[^\d,]/g, '').split(',');
    color = { R: rgbArr[0], G: rgbArr[1], B: rgbArr[2] };
  }

  const { R, G, B } = color;

  const C = [R / 255, G / 255, B / 255];

  for (let i = 0; i < C.length; ++i) {
    if (C[i] <= 0.03928) {
      C[i] = C[i] / 12.92;
    } else {
      C[i] = Math.pow((C[i] + 0.055) / 1.055, 2.4);
    }
  }

  const L = 0.2126 * C[0] + 0.7152 * C[1] + 0.0722 * C[2];

  return (L > 0.179) ? '#000000' : '#ffffff';
}
