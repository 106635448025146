import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import '../styles/pageNotFound.scss';

class PageNotFound extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    // this.goBack = this.goBack.bind(this);
  }

  // goBack() {
  //   this.props.history.goBack();
  // }

  render() {
    return (
      <div className="page-not-found">
        <div className="page-not-found-container">
          <h1>404</h1>
          <h2>Missing session ID in URL</h2>
          {/*<div className="back-link" onClick={this.goBack}>{this.props.t('common.goBack')}</div>*/}
        </div>
      </div>
    );
  }
}

const routerComp = withRouter(PageNotFound);

export default withTranslation([])(routerComp);
