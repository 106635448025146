import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styled from 'styled-components';
import { defaultTheme } from '../../constants/appConfig';
import DisabledIcon from '../../assets/svg/disabled.svg';
import Tooltip from './Tooltip';

const StyledLineMenu = styled.div`
  .ui-linemenu-item {
    &.active {
      color: ${props => (props.theme ? props.theme.primaryBgColor : defaultTheme.primaryBgColor)};
    }
  }
  .ui-linemenu-line {
    background: ${props => (props.theme ? props.theme.primaryBgColor : defaultTheme.primaryBgColor)};
  }
`;

export default class LineMenu extends React.PureComponent {
  static propTypes = {
    currentItemId: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
      tooltipText: PropTypes.string,
    })).isRequired,
    viewport: PropTypes.object,
    onChange: PropTypes.func,
    paymentMethodsStyle: PropTypes.string,
  };

  static defaultProps = {
    onChange: () => {},
    viewport: {},
    paymentMethodsStyle: 'underlined',
  };

  constructor(props) {
    super(props);

    this.state = {
      openedTooltip: '',
    };
    this.line = React.createRef();
  }

  componentDidMount() {
    this.calcLineStyle();
  }

  componentDidUpdate(prevProps) {
    const { viewport, currentItemId } = this.props;

    if ((prevProps.currentItemId !== currentItemId) || (prevProps.viewport.size !== viewport.size)) {
      setTimeout(this.calcLineStyle);
    }
  }

  onItemClick(item) {
    if (this.isActive(item.id)) {
      return;
    }

    if (item.disabled) {
      this.setState({ openedTooltip: item.id });
      return;
    }

    this.props.onChange(item.id);
  }

  onTooltipClose = () => {
    this.setState({ openedTooltip: '' });
  };

  calcLineStyle = () => {
    const { currentItemId } = this.props;
    const currentItemEl = this[`item-${currentItemId}`];
    const padding = parseInt(window.getComputedStyle(currentItemEl).paddingLeft, 10);
    const width = `${currentItemEl.clientWidth - (padding * 2)}px`;
    const left = `${currentItemEl.offsetLeft + padding}px`;

    this.line.current.style.width = width;
    this.line.current.style.transform = `translateX(${left})`;
  };

  isActive(id) {
    return this.props.currentItemId === id;
  }

  renderItems() {
    const { openedTooltip } = this.state;

    return this.props.items.map((item) => {
      const className = classnames('ui-linemenu-item', {
        active: this.isActive(item.id),
        disabled: item.disabled || false,
      });

      return (
        <div
          key={item.id}
          ref={ref => this[`item-${item.id}`] = ref}
          className={className}
          onClick={() => this.onItemClick(item)}
        >
          <span className="ui-linemenu-item-text">{item.label}</span>
          {item.disabled && (
            <Tooltip opened={openedTooltip === item.id} onClose={this.onTooltipClose} tooltipText={item.tooltipText}>
              {({ ref }) => (
                <span ref={ref} className="disabled-icon-wrap"><DisabledIcon className="disabled-icon" /></span>
              )}
            </Tooltip>
          )}
        </div>
      );
    });
  }

  render() {
    const { items } = this.props;
    if (items.length === 0) {
      return null;
    }

    return (
      <StyledLineMenu className={classnames('ui-linemenu', { 'full-width': items.length > 2 && !items.some(({ id }) => id === 'sbp') })}>
        {this.renderItems()}
        <span className="ui-linemenu-line" ref={this.line} />
      </StyledLineMenu>
    );
  }
}
