import React, { forwardRef } from 'react';
import { wrapDisplayName } from 'recompose';
import Viewport from './Viewport';

function withViewport(sizes = undefined) {
  function Wrapper(Component) {
    const WithViewport = forwardRef((props, ref) => <Viewport render={viewportProps => <Component ref={ref} {...props} {...viewportProps} />} sizes={sizes} />);

    WithViewport.displayName = wrapDisplayName(Component, 'withViewport');
    WithViewport.WrappedComponent = Component;

    return WithViewport;
  }

  return Wrapper;
}

export default withViewport;
