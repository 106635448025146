import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import acceptLanguageParser from 'accept-language-parser';

import '../styles/pageError.scss';
import { errorCodeToMessageTranslationKey } from '../utils/errorCodeToMessageTranslationKey';

const codes404 = ['1404', '2404'];

class PageError extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
  };

  componentDidMount() {
    let locale = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;
    if (window.clientAcceptLanguage) {
      locale = acceptLanguageParser.pick(['ru', 'tj', 'en'], window.clientAcceptLanguage, { loose: true });
    }
    this.props.i18n.changeLanguage(locale);
  }

  render() {
    const { t, match } = this.props;
    const { params } = match;
    const is404 = codes404.indexOf(params.code) > -1;

    return (
      <div className="page-error">
        <div className="page-error-container">
          {params.code === '2405' ? (
            <h1 dangerouslySetInnerHTML={{ __html: t(`errors.${errorCodeToMessageTranslationKey[params.code]}`) }} />
          ) : (
            <h1 className="big-error">{is404 ? '404' : t('common.error')}</h1>
          )}
          {params.code !== '2405' && <h2 dangerouslySetInnerHTML={{ __html: t([`errors.${errorCodeToMessageTranslationKey[params.code]}`, 'errors.something_went_wrong']) }} />}
        </div>
      </div>
    );
  }
}

const routerComp = withRouter(PageError);

export default withTranslation([])(routerComp);
