export default class Backend {
  static type = 'backend';

  constructor(services, options = {}) {
    this.init(services, options);

    this.type = 'backend';
  }

  init() {
    // this.services = services;
    // this.options = Object.assign({}, options, defaults);
  }

  read(language, namespace, callback) {
    import(`./locales/${language}/${namespace}.json`).then(module => callback(null, module)).catch(() => callback(null, {}));
  }
}
