import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styled, { withTheme } from 'styled-components';
import { defaultTheme } from '../../constants/appConfig';
import LineMenu from '../ui/LineMenu';
import BackArrowIcon from '../../assets/svg/back-arrow.svg';
import BackArrowIconMobile from '../../assets/svg/arrow-left.svg';
import Svg from '../ui/Svg';
import { ViewportSize } from '../viewport/Viewport';
import { StepHeaderContext } from './StepHeaderManager';

const StyledBackBtn = styled.div`
  &:hover {
    .back-arrow-icon {
      .fill-colored-el {
        fill: ${props => (props.theme ? props.theme.primaryBgColor : defaultTheme.primaryBgColor)};
      }
    }
    .back-text {
      color: ${props => (props.theme ? props.theme.primaryBgColor : defaultTheme.primaryBgColor)};
    }
  }
`;

class StepHeader extends PureComponent {
  static propTypes = {
    viewport: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    theme: PropTypes.object,
  };

  static contextType = StepHeaderContext;

  render() {
    if (!this.context) {
      console.error('[StepHeader] StepHeaderManager not found!');
      return null;
    }

    if (!this.context.props) {
      return null;
    }

    const { viewport, t, theme } = this.props;
    const dark = theme.formTheme === 'dark';
    const {
      title, subtitle, tabs, activeTabId, iconPath, onTabChange, backBtnShown, onBackClick,
    } = this.context.props;

    const isDesktop = viewport.size === ViewportSize.DESKTOP;

    return (
      <div className={classnames('content-block-header', { dark })}>
        <h2 className={classnames('h2', { hidden: !!iconPath })} dangerouslySetInnerHTML={{ __html: title }} />
        {iconPath && <div className="header-icon"><Svg path={iconPath} /></div>}
        {!!subtitle && (
          <p className="help-text" dangerouslySetInnerHTML={{ __html: subtitle }} />
        )}
        {!!backBtnShown && (
          <StyledBackBtn className="back-btn" onClick={onBackClick}>
            {isDesktop && <BackArrowIcon className="back-arrow-icon" />}
            {!isDesktop && <BackArrowIconMobile className="back-arrow-icon" />}
            <span className="back-text">{t('common.back')}</span>
          </StyledBackBtn>
        )}
        {tabs.length > 1 && (
          <LineMenu items={tabs} currentItemId={activeTabId} onChange={onTabChange} viewport={viewport} />
        )}
      </div>
    );
  }
}

export default withTheme(StepHeader);
