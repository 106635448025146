import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router-dom';
import styled, { css, ThemeProvider } from 'styled-components';
import { compose } from 'recompose';
import classnames from 'classnames';
import moment from 'moment';
import Color from 'color';
import { cubicNikitosa } from '../constants/styleConstants';
import isNoHeaderForm from '../utils/isNoHeaderForm';
import StepHeader from './header/StepHeader';
import StepHeaderManager from './header/StepHeaderManager';
import CardTokenizatorPaymentManager from './acquiring-buttons/CardTokenizatorPaymentContext';
import AppRoute from './AppRoute';
import InfoBlock from './info-block/InfoBlock';
import SupportIcon from '../assets/svg/message-white.svg';
import AlertAutoclose from './ui/AlertAutoclose';
import VisaIcon from '../assets/svg/verified-by-visa.svg';
import MasterCardIcon from '../assets/svg/mastercard-securecode.svg';
import PciIcon from '../assets/svg/pci-dss.svg';
import LockIcon from '../assets/svg/lock.svg';
import '../styles/app.scss';
import { ViewportSize } from './viewport/Viewport';
import withViewport from './viewport/withViewport';

import PayMethodStep from './steps/pay-method/PayMethodStep';
import { defaultTheme } from '../constants/appConfig';
import { isIFrame } from '../utils/checkIframe';

const ProductsStep = lazy(() => import(/* webpackChunkName: "ProductsStep", webpackPrefetch: true */ './steps/products/ProductsStep'));
const DeliveryStep = lazy(() => import(/* webpackChunkName: "DeliveryStep", webpackPrefetch: true */ './steps/delivery/DeliveryStep'));
const OtherPayMethod = lazy(() => import(/* webpackChunkName: "OtherPayMethod", webpackPrefetch: true */ './steps/pay-method/OtherPayMethod'));
const OtherPayMethodsList = lazy(() => import(/* webpackChunkName: "OtherPayMethodsList", webpackPrefetch: true */ './steps/pay-method/OtherPayMethodsList'));
// const PayMethodStep = lazy(() => import(/* webpackChunkName: "PayMethodStep" */ './steps/pay-method/PayMethodStep'));
const PayConfirmationStep = lazy(() => import(/* webpackChunkName: "PayConfirmationStep", webpackPrefetch: true */ './steps/pay-confirmation/PayConfirmationStep'));
const PayStatusStep = lazy(() => import(/* webpackChunkName: "PayStatusStep", webpackPrefetch: true */ './steps/pay-status/PayStatusStep'));
const SupportPage = lazy(() => import(/* webpackChunkName: "SupportPage", webpackPrefetch: true */ './support/SupportPage'));
const AdditionalInfoStep = lazy(() => import(/* webpackChunkName: "AdditionalInfoStep", webpackPrefetch: true */ './steps/additional-info/AdditionalInfoStep'));
const PayRecurrentStep = lazy(() => import(/* webpackChunkName: "PayRecurrentStep", webpackPrefetch: true */ './steps/pay-recurrent/PayRecurrentStep'));

const stepKeyToComponent = {
  products: ProductsStep,
  delivery: DeliveryStep,
  info: AdditionalInfoStep,
  recurrent: PayRecurrentStep,
  methods: PayMethodStep,
};

const Wrapper = styled.div`
  ${(p) => !p.$isIFrame && css`
    background: ${props => props.theme.backgroundColor};
`}

  .tablet-shadow-block {
    box-shadow: -15px 0 30px 0 ${(props) => {
      const lightness = Color(props.theme.primaryBgColor).hsl().object().l;
      return Color(props.theme.primaryBgColor).rotate(1).desaturate(0.79).lightness(lightness - 27).fade(.75).round().rgb();
    }};
  }
`;

const StepBlock = styled.div`
  background: ${props => (props.theme.formTheme === 'light' ? '#fff' : '#000')};
  box-shadow: -15px 20px 30px 0 ${(props) => {
    const lightness = Color(props.theme.primaryBgColor).hsl().object().l;
    return Color(props.theme.primaryBgColor).rotate(1).desaturate(0.79).lightness(lightness - 27).fade(.75).round().rgb();
  }};

  @media (max-width: 879px) {
    box-shadow: none;
  }
`;

class App extends React.PureComponent {
  static propTypes = {
    app: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    formData: PropTypes.object.isRequired,
    updateStoreDataField: PropTypes.func.isRequired,
    changeCurrentStep: PropTypes.func.isRequired,
    getForm: PropTypes.func.isRequired,
    initPayment: PropTypes.func.isRequired,
    sendConfirmationCode: PropTypes.func.isRequired,
    checkConfirmationCode: PropTypes.func.isRequired,
    getPaymentStatus: PropTypes.func.isRequired,
    getPaymentData: PropTypes.func.isRequired,
    getEmail: PropTypes.func.isRequired,
    saveEmail: PropTypes.func.isRequired,
    sendEmail: PropTypes.func.isRequired,
    calcFee: PropTypes.func.isRequired,
    createIssue: PropTypes.func.isRequired,
    addDelivery: PropTypes.func.isRequired,
    saveDelivery: PropTypes.func.isRequired,
    resetTxData: PropTypes.func.isRequired,
    saveFormData: PropTypes.func.isRequired,
    saveFieldsByMethodFormData: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    i18n: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    viewport: PropTypes.object.isRequired,
    setLinkExpired: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: true, // TODO
      tabletInfoExpanded: true,
    };

    this.contentBlockWrap = React.createRef();
    this.secureFooter = React.createRef();
    this.tabletShadow = React.createRef();
    this.intervalId = null;
  }

  componentDidMount() {
    const { app: { endTime }, getForm, match: { params: { sessionId } }, history } = this.props;

    if (endTime) {
      const days = Math.floor((endTime - Date.now()) / 1000 / 60 / 60 / 24);

      if (days === 0) {
        this.intervalId = setInterval(() => {
          const { location: { pathname } } = this.props;
          const isStatusOrSupportPage = (
            pathname.indexOf('/pending/') > -1
            || pathname.indexOf('/error/') > -1
            || pathname.indexOf('/success/') > -1
            || pathname.indexOf('/support') > -1
          );
          if (Date.now() >= endTime && !isStatusOrSupportPage) {
            clearInterval(this.intervalId);
            this.props.setLinkExpired();
          }
        }, 1000);
      }
    }

    // eslint-disable-next-line no-underscore-dangle
    // if (window.__PRELOADED_STATE_ERROR__) {
    //   history.push('/error');
    //   return;
    // }
    // TODO
    getForm({ sessionId })
      .then(() => {
        this.setLang()
          .then(() => {
            this.setState({ loading: false });
          });
      })
      .catch(() => {
        history.push('/error');
      });
  }

  componentDidUpdate(prevProps) {
    const { app, match, history, location } = this.props;
    if (prevProps.app !== app) {
      if (app.linkAlreadyPaid && location.pathname.indexOf(`/success/${app.txId}`) === -1) {
        const { params: { sessionId } } = match;
        history.replace(`/${sessionId}/success/${app.txId}`);
      }
    }
  }

  onAlertClose = () => {
    this.props.updateStoreDataField({ field: 'apiError.shown', value: false });
  };

  onStepDidMount = () => {
    const { data: { amountAndProductShown }, updateStoreDataField } = this.props;

    if (!amountAndProductShown) {
      updateStoreDataField({ field: 'amountAndProductShown', value: true });
    }
  };

  onSupportClick = () => {
    const { match: { params: { sessionId } }, history, location } = this.props;

    const supportUrl = `/${sessionId}/support`;

    if (location.pathname !== supportUrl) {
      history.push(supportUrl);
    }
  };

  onTabletInfoExpand = translateY => new Promise((resolve) => {
    this.setState(prevState => ({ tabletInfoExpanded: !prevState.tabletInfoExpanded }), () => {
      const { tabletInfoExpanded } = this.state;

      const shadowEl = this.tabletShadow.current;
      const contentEl = this.contentBlockWrap.current;
      const footerEl = this.secureFooter.current;

      const scaleFactor = translateY / shadowEl.clientHeight;

      const animateEl = (el, prop, resolving) => {
        const onTransitionEnd = (e) => {
          if (el !== e.target) {
            return;
          }

          el.removeEventListener('transitionend', onTransitionEnd);

          if (tabletInfoExpanded !== this.state.tabletInfoExpanded) return;

          if (!tabletInfoExpanded) {
            el.style.transform = '';
          }
          el.style.transition = '';
          if (resolving) {
            resolve(tabletInfoExpanded);
          }
        };

        if (tabletInfoExpanded) {
          el.style.transform = prop;
          el.getBoundingClientRect();
          el.style.transition = `transform 0.3s ${cubicNikitosa}`;
        } else {
          el.style.transition = `transform 0.3s ${cubicNikitosa}`;
        }
        el.getBoundingClientRect();
        el.addEventListener('transitionend', onTransitionEnd);
        el.style.transform = tabletInfoExpanded ? '' : prop;
      };

      animateEl(footerEl, `translateY(${-translateY}px)`);
      animateEl(contentEl, `translateY(${-translateY}px)`);
      animateEl(shadowEl, `scaleY(${1 - scaleFactor})`, true);
    });
  });

  // onStepWillUnmount = () => {
  //   const html = window.document.getElementsByTagName('html')[0];
  //
  //   html.classList.remove(`page-${this.state.page}`);
  // };

  setLang = () => new Promise((resolve) => {
    const { i18n, app: { locale }, t } = this.props;

    moment.defineLocale('short-locale', {
      parentLocale: locale,
    });

    i18n.on('languageChanged', () => {
      const relativeTimeWithPlural = (number, withoutSuffix, key) => {
        const format = {
          ss: t('common.short_second'),
          mm: t('common.short_minute'),
          hh: t('common.short_hour'),
          dd: t('common.short_day'),
          MM: t('common.short_month'),
          yy: t('common.short_year'),
        };

        return `${number} ${format[key]}`;
      };

      moment.updateLocale('short-locale', {
        relativeTime: {
          s: `1 ${t('common.short_second')}`,
          ss: relativeTimeWithPlural,
          m: relativeTimeWithPlural,
          mm: relativeTimeWithPlural,
          h: `1 ${t('common.short_hour')}`,
          hh: relativeTimeWithPlural,
          d: `1 ${t('common.short_day')}`,
          dd: relativeTimeWithPlural,
          M: `1 ${t('common.short_month')}`,
          MM: relativeTimeWithPlural,
          y: `1 ${t('common.short_year')}`,
          yy: relativeTimeWithPlural,
        },
      });

      moment.locale(locale);
      resolve();
    });

    i18n.changeLanguage(locale);
  });

  getExtraData = ({ methodUrl }) => {
    const { app: { product, steps }, data, formData: { delivery } } = this.props;
    const extra = { methodUrl, productName: product.name, productUrl: product.url };

    if (data.extra && data.extra.info) {
      const infoStep = steps.find(step => step.key === 'info');
      extra.info = {};

      infoStep.subSteps.forEach((subStep) => {
        subStep.fields.forEach((field) => {
          if (data.extra.info[field.name]) {
            extra.info[field.name] = data.extra.info[field.name];
            // extra.info[field.name] = field.type === 'number' ? data.extra.info[field.name] * 1 : data.extra.info[field.name];
          }
        });
      });
    }

    if (delivery) {
      extra.delivery = delivery;
      extra.paymentAmount = data.payment.amount;
    }

    return extra;
  };

  renderSecureFooter = () => (
    <div className="secure-payment" ref={this.secureFooter}>
      <a href="https://teko.io" className="teko-link" target="_blank" rel="noopener noreferrer">
        <LockIcon className="secure-icon" />
        <span className="secure-text">SECURE PAYMENT WITH TEKO</span>
      </a>
    </div>
  );

  renderMainRoutes = () => {
    const {
      app: { steps, theme }, match: { params: { sessionId }, path: basePath }, app, data, formData,
      updateStoreDataField, changeCurrentStep, initPayment, sendConfirmationCode, checkConfirmationCode, calcFee,
      history, match, getPaymentStatus, getPaymentData, getEmail, saveEmail, sendEmail, createIssue, resetTxData,
      addDelivery, saveDelivery, saveFormData, saveFieldsByMethodFormData, location, viewport, t,
    } = this.props;
    const currentStepKey = steps[0].key;
    const redirectUrl = `/${sessionId}/${currentStepKey}`;

    const commonPagesProps = {
      app,
      data,
      formData,
      updateStoreDataField,
      changeCurrentStep,
      history,
      match,
      onStepDidMount: this.onStepDidMount,
    };

    const deliveryPageProps = {
      ...commonPagesProps,
      addDelivery,
      saveDelivery,
    };

    const methodPagesProps = {
      ...commonPagesProps,
      initPayment,
      calcFee,
      saveFormData,
      saveFieldsByMethodFormData,
      sendConfirmationCode,
      getPaymentStatus,
      getExtraData: this.getExtraData,
    };

    const confirmationPageProps = {
      ...commonPagesProps,
      sendConfirmationCode,
      checkConfirmationCode,
    };

    const statusPagesProps = {
      ...commonPagesProps,
      getPaymentStatus,
      getPaymentData,
      getEmail,
      saveEmail,
      sendEmail,
      resetTxData,
    };

    const supportPageProps = {
      ...commonPagesProps,
      createIssue,
      resetTxData,
    };

    const noHeader = isNoHeaderForm(app) || (location.pathname.includes('/bank-card/sbp') && viewport.size === ViewportSize.DESKTOP);

    return (
      <StepBlock className="step-block">
        <CardTokenizatorPaymentManager>
          <StepHeaderManager>
            {!noHeader && <StepHeader t={t} viewport={viewport} />}
            <div className={classnames('content-block', { noHeader, dark: theme.formTheme === 'dark' })}>
              <Suspense fallback={<div />}>
                <Switch>
                  {steps.filter(step => step.key !== 'methods').map((step) => {
                    const Component = stepKeyToComponent[step.key];

                    return (
                      <AppRoute key={step.key} path={`${basePath}/${step.key}`} component={Component} pageProps={step.key === 'delivery' ? deliveryPageProps : commonPagesProps} />
                    );
                  })}
                  <AppRoute key="other-group-method" exact path={`${basePath}/methods/other/:group/:method`} component={OtherPayMethod} pageProps={methodPagesProps} />
                  <AppRoute key="other-group" exact path={`${basePath}/methods/other/:group`} component={OtherPayMethodsList} pageProps={commonPagesProps} />
                  <AppRoute key="card-mobile-other" path={`${basePath}/methods/:tab(bank-card|mobile-pay|sbp|other|tinkoff-bnpl)?`} component={PayMethodStep} pageProps={methodPagesProps} />
                  <AppRoute key="confirmation" path={`${basePath}/confirmation/:transactionId?`} component={PayConfirmationStep} pageProps={confirmationPageProps} />
                  <AppRoute key="status" path={`${basePath}/:status(pending|success|error)/:transactionId?`} component={PayStatusStep} pageProps={statusPagesProps} />
                  <AppRoute key="support" path={`${basePath}/support`} component={SupportPage} pageProps={supportPageProps} />
                  <Route path={`/${sessionId}`} render={() => <Redirect to={redirectUrl} />} />
                </Switch>
              </Suspense>
            </div>
          </StepHeaderManager>
        </CardTokenizatorPaymentManager>
      </StepBlock>
    );
  };

  render() {
    const { loading, tabletInfoExpanded } = this.state;

    if (loading) {
      return (
        <div className="app-component">
          {/*<UiLoader />*/}
        </div>
      );
    }

    const {
      app, app: { theme, linkExpired, linkIsActive }, data, data: { apiError }, formData,
      location, history, match, match: { params: { sessionId } }, t, viewport,
    } = this.props;

    const mergedTheme = {
      ...defaultTheme,
      ...theme,
    };

    if (linkExpired || !linkIsActive) {
      return (
        <div className="page-error">
          <div className="page-error-container">
            <h1>{t(`common.${!linkIsActive ? 'invoice_canceled' : 'invoice_expired'}`)}</h1>
          </div>
        </div>
      );
    }

    const alertShown = apiError && apiError.shown;
    const alertContent = (apiError && apiError.messageKey) ? t(`errors.${apiError.messageKey}`) : '';
    const infoBlockProps = {
      app,
      data,
      formData,
      sessionId,
      tabletExpanded: tabletInfoExpanded,
      onTabletExpand: this.onTabletInfoExpand,
      location,
      history,
      match,
      viewport,
    };

    return (
      <ThemeProvider theme={mergedTheme}>
        <Wrapper $isIFrame={isIFrame()} className="app-component">
          <div className="main-container">
            <div className="info-block-wrap">
              <InfoBlock {...infoBlockProps} />
              {viewport.size === ViewportSize.DESKTOP && (
                <div className="verified-by-wrap">
                  <div className="verified-by-item">
                    <VisaIcon />
                  </div>
                  <div className="verified-by-item">
                    <MasterCardIcon />
                  </div>
                  <div className="verified-by-item">
                    <PciIcon />
                  </div>
                </div>
              )}
            </div>
            <div className="content-block-wrap" ref={this.contentBlockWrap}>
              <AlertAutoclose
                color={AlertAutoclose.Color.white}
                isOpen={alertShown}
                closeTimeoutMS={4400}
                closeOnClick
                onClose={this.onAlertClose}
              >
                <span dangerouslySetInnerHTML={{ __html: alertContent }} />
                {/*{process.env.NODE_ENV === 'development' && (<audio src="/assets/audio/say_what_again.mp3" autoPlay />)}*/}
              </AlertAutoclose>
              {this.renderMainRoutes()}
              {viewport.size !== ViewportSize.DESKTOP && (
                <div className={classnames('client-support-block-tablet-mobile', { dark: theme.formTheme === 'dark' })} onClick={this.onSupportClick}>
                  <div className="support-text" dangerouslySetInnerHTML={{ __html: t('infoBlock.support') }} />
                  <div className="support-icon-wrap">
                    <SupportIcon className="support-icon" />
                  </div>
                </div>
              )}
              {viewport.size === ViewportSize.DESKTOP && this.renderSecureFooter()}
            </div>
            {viewport.size !== ViewportSize.DESKTOP && <div className="tablet-shadow-block" ref={this.tabletShadow} />}
          </div>
          {viewport.size !== ViewportSize.DESKTOP && this.renderSecureFooter()}
        </Wrapper>
      </ThemeProvider>
    );
  }
}

const enhance = compose(
  withTranslation(['translation']),
  withViewport(),
);


export default enhance(App);
