import React, { Fragment, lazy, PureComponent, Suspense } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Redirect, Switch } from 'react-router-dom';
import { compose } from 'recompose';
import AppRoute from '../../AppRoute';
import StepHeaderProps from '../../header/StepHeaderProps';
import { ViewportSize } from '../../viewport/Viewport';
import withViewport from '../../viewport/withViewport';
import './payMethodStep.scss';

const BankCard = lazy(() => import(/* webpackChunkName: "BankCard", webpackPrefetch: true */ './BankCard'));
const MobilePay = lazy(() => import(/* webpackChunkName: "MobilePay", webpackPrefetch: true */ './MobilePay'));
const Sbp = lazy(() => import(/* webpackChunkName: "Sbp", webpackPrefetch: true */ './Sbp'));
const TinkoffBNPL = lazy(() => import(/* webpackChunkName: "TinkoffBNPL", webpackPrefetch: true */ './TinkoffBNPL'));
const OtherPayMethodsGroups = lazy(() => import(/* webpackChunkName: "OtherPayMethodsGroups", webpackPrefetch: true */ './OtherPayMethodsGroups'));

const thisStepKey = 'methods';

class PayMethodStep extends PureComponent {
  static propTypes = {
    app: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    formData: PropTypes.object.isRequired,
    calcFee: PropTypes.func.isRequired,
    initPayment: PropTypes.func.isRequired,
    getPaymentStatus: PropTypes.func.isRequired,
    updateStoreDataField: PropTypes.func.isRequired,
    changeCurrentStep: PropTypes.func.isRequired,
    onStepDidMount: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    viewport: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.onStepDidMount();
  }

  onTabChange = (tab) => {
    const { match: { url, params } } = this.props;

    this.props.history.push(url.replace(params.tab, tab));
  };

  render() {
    const {
      app: { steps, payMethods }, data: { payment: { amount }, currentStepIndex }, formData,
      changeCurrentStep, match: { params, params: { sessionId } }, history, location, viewport, t,
    } = this.props;

    const thisStep = steps.find(step => step.key === thisStepKey);

    const thisStepIndex = steps.findIndex(step => step.key === thisStepKey);

    const { tab: tabKey } = params;

    const tabConfig = thisStep.tabs.find(tab => tab.key === tabKey);

    const isDesktop = viewport.size === ViewportSize.DESKTOP;

    const getTabLabel = tab => (isDesktop ? tab.title : tab.shortTitle || tab.title);

    if (thisStepIndex > currentStepIndex) return <Redirect to={`/${sessionId}/${steps[currentStepIndex].key}`} />;

    if (!tabKey || !tabConfig) {
      let urlToRedirect = thisStep.tabs[0].key;
      const fdKeys = Object.keys(formData.byMethodKey);

      if (fdKeys.length && payMethods[formData.lastModified]) {
        const lastModifiedMethod = payMethods[formData.lastModified];
        const isOtherTab = lastModifiedMethod.tabKey === 'other';
        urlToRedirect = isOtherTab ? `${lastModifiedMethod.tabKey}/${lastModifiedMethod.groupKey}/${lastModifiedMethod.key}` : lastModifiedMethod.tabKey;
      }

      return <Redirect to={`/${sessionId}/${thisStepKey}/${urlToRedirect}`} />;
    }

    const methodConfig = tabConfig.groups[0].methods[0];

    const checkTabDisabled = tab => !tab.groups
      .some(group => group.methods.some(method => !method.maxAmount || method.maxAmount >= amount));

    const tabs = thisStep.tabs.map(tab => ({ id: tab.key, label: getTabLabel(tab), disabled: checkTabDisabled(tab), tooltipText: t('methods.limit_exceeded') }));

    const prevStep = thisStepIndex > 0 ? steps[thisStepIndex - 1] : steps[thisStepIndex];
    const prevSubStepKey = prevStep.subSteps ? `/${prevStep.subSteps[prevStep.subSteps.length - 1].key}` : '';

    const prevStepKey = thisStepIndex > 0 ? `${prevStep.key}${prevSubStepKey}` : thisStep.key;
    const prevStepUrl = `/${sessionId}/${prevStepKey}`;

    // const nextStepKey = thisStepIndex + 1 < steps.length ? steps[thisStepIndex + 1].key : thisStep.key;
    // const nextStepUrl = url.replace(thisStep.key, nextStepKey);

    // (thisStep.tabs[0].groups.length > 1 || thisStep.tabs[0].groups[0].methods.length > 1)

    const handleBackClick = () => {
      if (location.pathname.includes('/bank-card/sbp')) {
        history.replace(`/${sessionId}/${thisStepKey}/bank-card`);
        return;
      }
      // if (location.pathname.includes('/methods/sbp')) {
      //   this.props.updateStoreDataField({ field: 'payment.commission', value: -1 });
      // }
      history.push(prevStepUrl);
      changeCurrentStep(thisStepIndex - 1);
    };

    const sbpProps = {
      ...this.props,
      methodConfig,
      isDesktop,
      sessionId,
    };

    return (
      <Fragment>
        <StepHeaderProps
          title={thisStep.tabs.length === 1 && thisStep.tabs[0].key !== 'other' ? thisStep.tabs[0].title : thisStep.title}
          backBtnShown={thisStepIndex > 0 || location.pathname.includes('/bank-card/sbp')}
          onBackClick={handleBackClick}
          tabs={tabs}
          activeTabId={tabKey}
          onTabChange={this.onTabChange}
        />
        <Suspense fallback={<div />}>
          <Switch>
            <AppRoute exact path={`/${sessionId}/${thisStepKey}/bank-card`} component={BankCard} pageProps={{ ...this.props, methodConfig, sessionId }} />
            <AppRoute exact path={`/${sessionId}/${thisStepKey}/bank-card/sbp`} component={Sbp} pageProps={sbpProps} />
            <AppRoute exact path={`/${sessionId}/${thisStepKey}/mobile-pay`} component={MobilePay} pageProps={{ ...this.props, methodConfig, sessionId }} />
            <AppRoute exact path={`/${sessionId}/${thisStepKey}/sbp`} component={Sbp} pageProps={sbpProps} />
            <AppRoute exact path={`/${sessionId}/${thisStepKey}/tinkoff-bnpl`} component={TinkoffBNPL} pageProps={{ ...this.props, methodConfig, sessionId }} />
            <AppRoute exact path={`/${sessionId}/${thisStepKey}/other`} component={OtherPayMethodsGroups} pageProps={{ ...this.props, sessionId }} />
          </Switch>
        </Suspense>
      </Fragment>
    );
  }
}

const enhance = compose(
  withTranslation(),
  withViewport(),
);

export default enhance(PayMethodStep);
