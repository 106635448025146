import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StepHeaderContext } from './StepHeaderManager';

class StepHeaderProps extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    backBtnShown: PropTypes.bool,
    tabs: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
      tooltipText: PropTypes.string,
    })),
    activeTabId: PropTypes.string,
    iconPath: PropTypes.string,
    onTabChange: PropTypes.func,
    onBackClick: PropTypes.func,
    update: PropTypes.func,
  };

  static defaultProps = {
    title: '',
    subtitle: '',
    backBtnShown: false,
    tabs: [],
    activeTabId: '',
    iconPath: '',
    onTabChange: () => {},
    onBackClick: () => {},
    update: null,
  };

  componentDidMount() {
    const { update, ...props } = this.props;

    if (update) {
      update(props);
    }
  }

  componentDidUpdate() {
    const { update, ...props } = this.props;

    if (update) {
      update(props);
    }
  }

  componentWillUnmount() {
    const { update } = this.props;

    if (update) {
      update(null);
    }
  }

  render() {
    return null;
  }
}

const StepHeaderPropsWithUpdater = props => (
  <StepHeaderContext.Consumer>
    {
      value => (
        <StepHeaderProps update={value ? value.update : null} {...props} />
      )
    }
  </StepHeaderContext.Consumer>
);

export default StepHeaderPropsWithUpdater;
